import React, { useEffect, useState } from "react";
import classes from "./Signup.module.css";
import { logo } from "../../../assets/constant";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Button from "../../../components/Button";
import { useNavigate } from "react-router-dom";
import { FaCheck } from "react-icons/fa6";
import { BaseURL, apiHeader } from "../../../config";
import { Get, Post } from "../../../AxiosCRUDFunctions";
import { toast } from "react-toastify";
import Loader from "../../../components/Loader";
import CommonCard from "../../../components/CommonCard";
import Step5 from "./Step5";

const Signup = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [isValidPhone, setIsValidPhone] = useState(false);
  const [loading, setLoading] = useState(false);
  const [speciality, setSpeciality] = useState(null);
  const [province, setProvince] = useState(null);
  const [cities, setCities] = useState(null);
  const [selectedAvatar, setSelectedAvatar] = useState(null);
  const [form, setForm] = useState({
    consultantName: "",
    speciality: "",
    academicQualification: "",
    position: "",
    image: "",

    emailAddress: "",
    phoneNo: "",
    password: "",
    confirmPassword: "",
    province: "",
    city: "",
    address: "",

    hospitalInstitutionName: "",
    hospitalContactNo: "",
    hospitalEmailAddress: "",
    hospitalAddress: "",

    privateClinicName: "",
    privateClinicContactNo: "",
    privateClinicEmailAddress: "",
    privateClinicAddress: "",
  });
  const validateEmail = (email) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const setAvatar = (img, type) => {
    setSelectedAvatar(type);
    setForm((prev) => ({ ...prev, image: img }));
  };
  const handleBack = () => {
    setLoading(true);
    if (step !== 1) {
      setStep((prev) => prev - 1);
    } else {
      navigate("/");
    }
    setLoading(false);
  };
  const handleNext = () => {
    if (
      step === 1 &&
      form?.consultantName &&
      form.speciality &&
      form?.academicQualification
    ) {
      setStep((prev) => prev + 1);
    } else if (
      step === 2 &&
      form?.emailAddress &&
      form.phoneNo &&
      form?.password &&
      form?.confirmPassword &&
      form?.province &&
      form?.city
    ) {
      if (form?.password !== form?.confirmPassword) {
        toast.error("Password and Confirm Password must be same");
      } else if (!isValidPhone) {
        toast.error("Must be a Valid phone number");
      } else if (!validateEmail(form?.emailAddress)) {
        toast.error("Must be a Valid Email Adress");
      } else {
        setStep((prev) => prev + 1);
      }
    } else if (step === 3 && form?.hospitalInstitutionName) {
      if (
        form?.hospitalInstitutionName &&
        form?.hospitalEmailAddress &&
        form?.hospitalAddress &&
        form?.hospitalContactNo
      ) {
        if (!isValidPhone) {
          toast.error("Must be a Valid phone number");
        } else if (!validateEmail(form?.hospitalEmailAddress)) {
          toast.error("Must be a Valid Email Adress");
        } else {
          setStep((prev) => prev + 1);
        }
      } else {
        toast.error("Required Fields must be filled");
      }
    } else if (step === 3) {
      setStep((prev) => prev + 1);
    } else if (step === 4 && form?.privateClinicName) {
      if (
        form?.privateClinicName &&
        form?.privateClinicContactNo &&
        form?.privateClinicEmailAddress &&
        form?.privateClinicAddress
      ) {
        if (!isValidPhone) {
          toast.error("Must be a Valid phone number");
        } else if (!validateEmail(form?.privateClinicEmailAddress)) {
          toast.error("Must be a Valid Email Adress");
        } else {
          setStep((prev) => prev + 1);
        }
      } else {
        toast.error("Required Fields must be filled");
      }
    } else if (step === 4) {
      setStep((prev) => prev + 1);
    } else if (step === 5 && form?.image) {
      handleSignup();
    } else {
      toast.error("Required Fields must be filled");
    }
  };
  const handleSignup = async () => {
    setLoading(true);

    const { confirmPassword, speciality, city, province, ...rest } = form;
    const specialityCode = speciality?.map((item) => item.value);
    const body = {
      ...rest,
      phoneNo: `+92${form?.phoneNo}`,
      specialityCode,
      provinceId: form?.province?.value,
      cityId: form?.city?.value,
    };
    const response = await Post(BaseURL("SignUp/SignUp"), body, apiHeader());
    if (response?.data?.body != null && response?.data?.headers.error === 200) {
      toast.success("Register Successfully");
      navigate("/");
    } else {
      toast.error(response?.data.headers?.message);
    }
    setLoading(false);
  };

  const steps = [1, 2, 3, 4];

  const loadControls = async () => {
    setLoading(true);
    const response = await Get(BaseURL("SignUp/LoadControls"), apiHeader());
    setSpeciality(response?.data?.body?.dbSpecialityList);
    setProvince(response?.data?.body?.dbProvinceList);
    setCities(response?.data?.body?.dbCityList);
    setLoading(false);
  };
  const loadCities = async (id) => {
    const response = await Get(
      BaseURL(`SignUp/GetCitiesByID?provinceId=${id}`)
    );
    setCities(response?.data?.body?.dbCityList);
  };

  useEffect(() => {
    loadControls();
  }, []);
  useEffect(() => {
    if (form?.province) {
      loadCities(form?.province?.value);
    }
  }, [form?.province]);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className={classes["main-wrapper"]}>
          <CommonCard className={classes["extraCardStyles"]}>
            <span className={classes["brand-logo"]}>
              <img src={logo && logo} alt="logo" />
            </span>
            <div className={classes["main-heading"]}>
              <h1>Register</h1>
              <p>
                Let's Join Hands to Prevent and Cure Cancers & Blood Diseases{" "}
              </p>
            </div>
            <div className={classes["step-indicator"]}>
              {steps.map((item) => (
                <>
                  <span
                    className={[
                      step >= item
                        ? classes["ind-step-done"]
                        : classes["ind-step-remaining"],
                      classes["ind-step"],
                    ].join(" ")}
                  >
                    {item < step ? <FaCheck fontSize={20} /> : item}
                  </span>
                  {item !== steps.length && (
                    <span
                      className={[
                        step > item
                          ? classes["ind-step-marker-done"]
                          : step === item
                          ? classes["ind-step-marker-process"]
                          : classes["ind-step-marker-remaining"],
                        classes["ind-step-marker"],
                      ].join(" ")}
                    ></span>
                  )}
                </>
              ))}
            </div>
            <div className={classes["steps-wrapper"]}>
              {step === 1 && (
                <Step1 data={form} setData={setForm} speciality={speciality} />
              )}
              {step === 2 && (
                <Step2
                  data={form}
                  setData={setForm}
                  cities={cities}
                  province={province}
                  setIsValidPhone={setIsValidPhone}
                />
              )}
              {step === 3 && (
                <Step3
                  data={form}
                  setData={setForm}
                  setIsValidPhone={setIsValidPhone}
                />
              )}
              {step === 4 && (
                <Step4
                  data={form}
                  setData={setForm}
                  setIsValidPhone={setIsValidPhone}
                />
              )}
              {step === 5 && (
                <Step5 selectedAvatar={selectedAvatar} setAvatar={setAvatar} />
              )}
            </div>
            <div className={classes["action-wrappper"]}>
              <Button
                className={[classes["custom-btn"], classes["btn"]].join(" ")}
                label={step === 1 ? "Cancel" : "Back"}
                onClick={handleBack}
              />
              <Button
                className={classes["btn"]}
                label={step === 5 ? "Finish" : "Next"}
                onClick={handleNext}
                disabled={step === 5 && !form?.image}
              />
            </div>
          </CommonCard>
        </div>
      )}
    </>
  );
};

export default Signup;
