import React, { useState } from "react";
import { Table } from "react-bootstrap";
import Button from "../../Button";
import FormCheckbox from "../../FormCheckbox";
import Paginatiion from "../../Pagination";
import PaginationWrapper from "../../PaginationWrapper";
import classes from "./PatientDataTable.module.css";
import "../../../styles/table.css";
import NoData from "../../NoData";
import StatusIndicator from "../../StatusIndicator";
import TableHeaderFilter from "../../TableHeaderFilters";
import { IoMdMore } from "react-icons/io";
import { Popover } from "@mui/material";

const PatientDataTable = ({ tableActions, handler, pdfHandler }) => {
  const {
    page,
    setPage,
    search,
    setSearch,
    limit,
    setLimit,
    totalCount,
    getAllRefferalPatientData,
    data,
  } = tableActions;
  const [selectedObj, setSelectedObj] = useState([]);
  const handleTopCheckbox = (e, id) => {
    const updatedList = e ? [...data] : [];
    setSelectedObj(updatedList);
    const checkboxes = document.querySelectorAll(
      ".individual-checkbox > input"
    );
    checkboxes.forEach((checkbox) => {
      checkbox.checked = e;
    });
  };
  const handleIndividualCheckbox = (e, id) => {
    const updatedList = e
      ? [...selectedObj, data?.find((ele) => ele?.rowNum === id)]
      : selectedObj?.filter((ele) => ele?.rowNum !== id);
    setSelectedObj(updatedList);
    const allChecked = data.every((data) => {
      return updatedList?.some(
        (selectedData) => selectedData?.rowNum === data?.rowNum
      );
    });
    const topChecked = document.querySelector(".top-checkbox > input");

    if (topChecked) {
      topChecked.checked = allChecked;
    }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div style={{ padding: "25px", overflowX: "auto", overflowY: "auto" }}>
      <div className={classes["wrapper"]}>
        <TableHeaderFilter
          filterActions={{ limit, setLimit: setLimit, search, setSearch }}
        />
        <div
          className={classes.scrollableContainer}
          style={{ overflowX: "auto", overflowY: "auto" }}
        >
          <Table>
            <thead style={{ position: "sticky", top: "0" }}>
              <tr>
                <th style={{ width: "5%", textAlign: "left" }}>
                  <FormCheckbox
                    className={[
                      "top-checkbox",
                      classes["table-item-checkbox"],
                      classes.topHeaderBox,
                    ].join(" ")}
                    setter={(e) => {
                      handleTopCheckbox(e);
                    }}
                  />
                </th>
                <th style={{ width: "15%", textAlign: "center" }}>
                  Patient ID
                </th>
                <th style={{ width: "20%", textAlign: "center" }}>
                  Patient Name
                </th>
                <th style={{ width: "15%", textAlign: "center" }}>Age</th>
                <th style={{ width: "15%", textAlign: "center" }}>Gender</th>
                <th style={{ width: "15%", textAlign: "center" }}>Referred</th>
                <th style={{ width: "15%", textAlign: "center" }}>Status</th>
                <th style={{ width: "15%", textAlign: "center" }}>Action</th>
              </tr>
            </thead>
            {data?.length > 0 && (
              <tbody>
                {data?.map((ele) => {
                  return (
                    <tr>
                      <td style={{ width: "5%", textAlign: "left" }}>
                        <FormCheckbox
                          className={[
                            "individual-checkbox",
                            classes["table-item-checkbox"],
                            classes.topHeaderBox,
                          ].join(" ")}
                          setter={(e) => {
                            handleIndividualCheckbox(e, ele?.rowNum);
                          }}
                          checked={selectedObj?.some(
                            (data) => data?.rowNum === ele?.rowNum
                          )}
                        />
                      </td>
                      <td style={{ width: "15%", textAlign: "center" }}>
                        {ele?.patientId}
                      </td>
                      <td style={{ width: "20%", textAlign: "center" }}>
                        {ele?.patientName}
                      </td>
                      <td style={{ width: "15%", textAlign: "center" }}>
                        {ele?.age}
                      </td>
                      <td style={{ width: "15%", textAlign: "center" }}>
                        {ele?.gender}
                      </td>
                      <td style={{ width: "15%", textAlign: "center" }}>
                        {ele?.referred}
                      </td>
                      <td style={{ width: "15%", textAlign: "center" }}>
                        <StatusIndicator
                          title={ele?.isMRNo ? "Active" : "Pending"}
                          bgColor={
                            ele?.isMRNo
                              ? "var(--primary-color)"
                              : "var(--coral)"
                          }
                        />
                      </td>
                      <td
                        style={{
                          width: "15%",
                          textAlign: "center",
                        }}
                      >
                        <IoMdMore
                          size={20}
                          onClick={handleClick}
                          style={{ cursor: "pointer" }}
                        />
                        <Popover
                          id={id}
                          open={open}
                          anchorEl={anchorEl}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                        >
                          <ul className={classes["popoverDiv"]}>
                            <li
                              onClick={() =>
                                handler(ele?.referralNetworkPatientID)
                              }
                            >
                              View Details
                            </li>
                            <li
                              onClick={() =>
                                pdfHandler(ele?.referralNetworkPatientID)
                              }
                            >
                              View Report
                            </li>
                          </ul>
                        </Popover>
                        {/* <Button
                          label={`Details`}
                          style={{ border: "1px solid #EDF2F7" }}
                          className={[
                            `button_transparent`,
                            classes.rowAction,
                          ].join(" ")}
                          onClick={() => handler(ele?.referralNetworkPatientID)}
                        /> */}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            )}
          </Table>
          {data?.length == 0 && <NoData />}
        </div>
        {data?.length > 0 && (
          <PaginationWrapper limit={data?.length} totalCount={totalCount}>
            <Paginatiion
              page={page}
              setPage={(e) => {
                if (e > 0 && e < Math.ceil(totalCount / limit?.value))
                  getAllRefferalPatientData(e);
                setPage(e);
              }}
              limit={limit?.value}
              totalCount={totalCount}
            />
          </PaginationWrapper>
        )}
      </div>
    </div>
  );
};

export default PatientDataTable;
