import React from "react";
import Navbar from "../../../components/Navbar";

const Publication = () => {
  return (
    <div>
      <Navbar title={"Publication"} />
    </div>
  );
};

export default Publication;
