import React, { useEffect, useState } from "react";
import classes from "./Navbar.module.css";
import ReferralForm from "../../modals/ReferralForm";
import { useSelector } from "react-redux";
import { Get, Post } from "../../AxiosCRUDFunctions";
import { BaseURL, apiHeader } from "../../config";
import moment from "moment";
import { toast } from "react-toastify";
import Button from "../Button";
import { FaCalculator, FaPlus } from "react-icons/fa";
import SuccessModal from "../../modals/SuccessModal";
import { defaultProfile } from "../../assets/constant";

const Navbar = ({ title, children }) => {
  const { token, user } = useSelector((state) => state?.authReducer);
  const [show, setShow] = useState(false);
  const [successShow, setSuccessShow] = useState(false);
  const [titleList, setTitleList] = useState([]);
  const [referToList, setReferToList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [successData, setSuccessData] = useState({});
  const [successData2, setSuccessData2] = useState({});
  const [loading, setLoading] = useState(false);

  const loadControls = async () => {
    const response = await Get(BaseURL("ReferralPatients/LoadControls"), token);
    setTitleList(response?.data?.body?.dbSalutationList);
    setReferToList(response?.data?.body?.referToList);
    setLocationList(response?.data?.body?.dbLocationList);
  };

  const handleSubmit = async (form) => {
    setLoading(true);
    const dateOfBirth = moment(form?.dob).format("MMMM DD YYYY, hh:mm:ss A");
    const { title, ...rest } = form;
    const body = {
      ...rest,
      patientId: 0,
      phoneNo: `+92${form?.phoneNo}`,
      patientSalutationId: form?.title.value,
      gender: form?.gender?.value,
      BCode: form?.BCode?.value,
      referTo: form?.referTo?.value,
      dob: dateOfBirth,
    };
    const response = await Post(
      BaseURL("ReferralPatients/SaveReferralPatient"),
      body,
      apiHeader(token)
    );
    if (response !== undefined && response?.data?.headers.error === 200) {
      toast.success("Referred Successfully");
      const { body } = response?.data;
      setSuccessData(
        <>
          Your Referral note has been received for your patient{" "}
          <b>
            {body?.patientName} ({body?.age}, {body?.gender}) with{" "}
            {body?.patientId}
          </b>
        </>
      );
      setSuccessData2(
        <>
          We will be accommodating your patient with the next available
          appointment with our <b>{body?.speciality}</b> Specialist.
        </>
      );
      setSuccessShow(true);
      setShow(false);
      // cancelHandler();
    } else {
      toast.error(response?.data.headers?.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadControls();
  }, []);

  return (
    <div className={classes["header"]}>
      <h1>{title}</h1>
      <div className={classes["actions"]}>
        <Button onClick={() => setShow(true)} className={classes["btn-plus"]}>
          <FaPlus size={15} style={{ marginRight: 5, marginTop: -3 }} />
          Refer a Patient
        </Button>
        <img src={user?.image ? user?.image : defaultProfile} alt="profile" />
      </div>
      <ReferralForm
        show={show}
        setShow={() => {
          setShow(false);
          // cancelHandler();
        }}
        titleList={titleList}
        referToList={referToList}
        locationList={locationList}
        handler={handleSubmit}
        loading={loading}
      />
      <SuccessModal
        show={successShow}
        setShow={() => setSuccessShow(false)}
        data={successData}
        data2={successData2}
      />
    </div>
  );
};

export default Navbar;
