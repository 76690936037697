import React from "react";
import classes from "./LocationCard.module.css";
import CommonCard from "../CommonCard";
import { Row } from "react-bootstrap";
import { FaLocationDot } from "react-icons/fa6";

const LocationCard = ({ data, handler }) => {
  return (
    <CommonCard
      className={classes["extraCardStyle"]}
      onClick={() => handler(data?.url)}
    >
      <Row className={classes["map-img"]}>
        <img src={require(`../../assets/images/${data?.image}`)} alt="" />
      </Row>
      <Row className={classes["main-heading"]}>
        <div style={{ display: "flex", gap: "10px" }}>
          <FaLocationDot size={20} color={"#BD081C"} />
          <h1>{data?.Name}</h1>
        </div>
        <p>{data?.Address}</p>
      </Row>
    </CommonCard>
  );
};

export default LocationCard;
