import React, { useEffect, useRef, useState } from "react";
import { BiSolidUserRectangle } from "react-icons/bi";
import { FaBars } from "react-icons/fa";
import { FaLocationDot, FaRegFolderOpen, FaUser } from "react-icons/fa6";
import { IoHome, IoLogOut, IoNotifications } from "react-icons/io5";
import { PiUserListFill } from "react-icons/pi";
import { RxCross1 } from "react-icons/rx";
import { useSelector } from "react-redux";
import { defaultProfile, logo, shortLogo } from "../../assets/constant";
import MenuItem from "../../components/MenuItem";
import classes from "./SidebarSkeleton.module.css";

const SidebarSkeleton = ({ children }) => {
  const { user } = useSelector((state) => state?.authReducer);
  const sidebarAside = useRef(null);
  const [isSidebarClose, setIsSidebarClose] = useState(false);
  const [active, setActive] = useState(null);
  const [isClickOnToggle, setIsClickOnToggle] = useState(false);
  const [markAsActive, setMarkAsActive] = useState(1);
  const detectListHandler = (idx) => {
    if (active === idx) {
      setActive(null);
    } else {
      setActive(idx);
    }
  };
  const handleResize = () => {
    if (window.innerWidth <= 992) {
      setIsSidebarClose(true);
    }
  };
  useEffect(() => {
    if (window.innerWidth < 440) {
      setIsSidebarClose(true);
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);
  return (
    <>
      <div className={classes["sidebar__wrapper"]}>
        <aside
          className={[
            classes["sidebar-side-transition"],
            classes["sidebar__sideContent"],
            isSidebarClose && classes["width-0"],
          ].join(" ")}
          ref={sidebarAside}
        >
          <div
            className={[
              classes["sidebar__mainMenu"],
              classes["overflow-x-hidden"],
            ].join(" ")}
          >
            <span
              className={[
                classes["sidebar__openerToggle"],
                isSidebarClose && classes["show__openerToggle"],
              ].join(" ")}
              onClick={() => {
                isSidebarClose
                  ? setIsClickOnToggle(true)
                  : setIsClickOnToggle(false);
                setIsSidebarClose((prev) => !prev);
              }}
            >
              {isSidebarClose && (
                <FaBars
                  color={`#fff`}
                  className={classes["toogle"]}
                  size={20}
                />
              )}
              {!isSidebarClose && (
                <RxCross1
                  color={`#fff`}
                  className={classes["toggle"]}
                  size={20}
                  style={{ strokeWidth: "1" }}
                />
              )}
            </span>
            <div
              className={[
                classes["sidebar__header"],
                isSidebarClose
                  ? classes["forshortLogo"]
                  : classes["forLongLogo"],
                classes["sidebarList"],
                classes["overflow-x-hidden"],
                `${isSidebarClose && "px-0"}`,
              ].join(" ")}
            >
              {!isSidebarClose && (
                <span className={classes["brand-logo"]}>
                  <img
                    src={logo && logo}
                    className={classes["apply-transition"]}
                    alt="logo"
                  />
                </span>
              )}
              {isSidebarClose && (
                <span
                  className={`${classes["ml-5px"]} ${classes["apply-transition"]} ${classes["brand-logo"]}`}
                >
                  <img
                    src={shortLogo && shortLogo}
                    className={` ${classes["short-logo"]}`}
                    alt="logo"
                  />
                </span>
              )}
            </div>
            <div className={classes["profile-image-wrapper"]}>
              <img
                src={user?.image ? user?.image : defaultProfile}
                alt="profile"
              />
            </div>
            <ul className={classes["sidebarList"]}>
              <MenuItem
                ref={sidebarAside}
                path={"/"}
                text={"Dashboard"}
                icon={<IoHome color="var(--blue-gray)" size={20} />}
                isClickOnToggle={isClickOnToggle}
                isSidebarClose={isSidebarClose}
                setIsSidebarClose={(isShow) => setIsSidebarClose(isShow)}
                activeHandler={() => detectListHandler(1)}
                markAsActive={markAsActive === 1}
                setMarkAsActive={() => setMarkAsActive(1)}
                isActive={active === 1}
              />
              <MenuItem
                ref={sidebarAside}
                path={"/patient"}
                text={"My Patient(s)"}
                icon={
                  <PiUserListFill
                    color="var(--blue-gray)"
                    size={20}
                    style={{ marginTop: 0 }}
                  />
                }
                isClickOnToggle={isClickOnToggle}
                isSidebarClose={isSidebarClose}
                setIsSidebarClose={(isShow) => setIsSidebarClose(isShow)}
                activeHandler={() => detectListHandler(2)}
                markAsActive={markAsActive === 2}
                setMarkAsActive={() => setMarkAsActive(2)}
                isActive={active === 2}
              />
              <MenuItem
                ref={sidebarAside}
                path={"/location"}
                text={"Location List"}
                icon={
                  <FaLocationDot
                    color="var(--blue-gray)"
                    size={20}
                    style={{ marginTop: -5 }}
                  />
                }
                isClickOnToggle={isClickOnToggle}
                isSidebarClose={isSidebarClose}
                setIsSidebarClose={(isShow) => setIsSidebarClose(isShow)}
                activeHandler={() => detectListHandler(3)}
                markAsActive={markAsActive === 3}
                setMarkAsActive={() => setMarkAsActive(3)}
                isActive={active === 3}
              />
              <MenuItem
                ref={sidebarAside}
                path={"/faculty"}
                text={"Faculty"}
                icon={
                  <BiSolidUserRectangle
                    color="var(--blue-gray)"
                    size={20}
                    style={{ marginTop: -3 }}
                  />
                }
                isClickOnToggle={isClickOnToggle}
                isSidebarClose={isSidebarClose}
                setIsSidebarClose={(isShow) => setIsSidebarClose(isShow)}
                activeHandler={() => detectListHandler(4)}
                markAsActive={markAsActive === 4}
                setMarkAsActive={() => setMarkAsActive(4)}
                isActive={active === 4}
              />

              <MenuItem
                ref={sidebarAside}
                path={"/document"}
                text={"Documents"}
                icon={
                  <FaRegFolderOpen
                    color="var(--blue-gray)"
                    size={20}
                    style={{ marginTop: -3 }}
                  />
                }
                isClickOnToggle={isClickOnToggle}
                isSidebarClose={isSidebarClose}
                setIsSidebarClose={(isShow) => setIsSidebarClose(isShow)}
                activeHandler={() => detectListHandler(5)}
                markAsActive={markAsActive === 5}
                setMarkAsActive={() => setMarkAsActive(5)}
                isActive={active === 5}
              />

              {/* <MenuItem
                ref={sidebarAside}
                path={"/referralCriteria"}
                text={"Referral Criteria"}
                icon={<HiUsers color="var(--blue-gray)" size={20} />}
                isClickOnToggle={isClickOnToggle}
                isSidebarClose={isSidebarClose}
                setIsSidebarClose={(isShow) => setIsSidebarClose(isShow)}
                activeHandler={() => detectListHandler(6)}
                markAsActive={markAsActive === 6}
                setMarkAsActive={() => setMarkAsActive(6)}
                isActive={active === 6}
              />
              <MenuItem
                ref={sidebarAside}
                path={"/publication"}
                text={"Publications"}
                icon={<HiUsers color="var(--blue-gray)" size={20} />}
                isClickOnToggle={isClickOnToggle}
                isSidebarClose={isSidebarClose}
                setIsSidebarClose={(isShow) => setIsSidebarClose(isShow)}
                activeHandler={() => detectListHandler(7)}
                markAsActive={markAsActive === 7}
                setMarkAsActive={() => setMarkAsActive(7)}
                isActive={active === 7}
              /> */}

              <div className={classes["down-actions-wrapper"]}>
                <div
                  className={[
                    classes["seprator-line"],

                    !isSidebarClose && classes["seprator-padding"],
                  ].join(" ")}
                ></div>
                <div className={classes["down-actions"]}>
                  <MenuItem
                    ref={sidebarAside}
                    path={"account"}
                    text={"Account"}
                    listOfOptions={null}
                    icon={
                      <FaUser
                        color="var(--blue-gray)"
                        size={18}
                        style={{ marginTop: -5 }}
                      />
                    }
                    isClickOnToggle={isClickOnToggle}
                    isSidebarClose={isSidebarClose}
                    setIsSidebarClose={(isShow) => setIsSidebarClose(isShow)}
                    activeHandler={() => detectListHandler}
                    markAsActive={markAsActive === 8}
                    isActive={active === 8}
                    setMarkAsActive={() => setMarkAsActive(8)}
                  />
                  <MenuItem
                    ref={sidebarAside}
                    path={"notification"}
                    text={"Notifications"}
                    listOfOptions={null}
                    icon={
                      <IoNotifications
                        color="var(--blue-gray)"
                        size={18}
                        style={{ marginTop: -5 }}
                      />
                    }
                    isClickOnToggle={isClickOnToggle}
                    isSidebarClose={isSidebarClose}
                    setIsSidebarClose={(isShow) => setIsSidebarClose(isShow)}
                    activeHandler={() => detectListHandler}
                    markAsActive={markAsActive === 9}
                    isActive={active === 9}
                    setMarkAsActive={() => setMarkAsActive(9)}
                  />

                  <MenuItem
                    className={[classes["mt-34"], classes["logout-icon"]].join(
                      " "
                    )}
                    ref={sidebarAside}
                    path={"/"}
                    text={"Logout"}
                    listOfOptions={null}
                    icon={
                      <IoLogOut
                        size={22}
                        color="var(--blue-gray)"
                        style={{ marginTop: -2 }}
                      />
                    }
                    markAsActive={markAsActive === 10}
                    setMarkAsActive={() => setMarkAsActive(10)}
                    isClickOnToggle={isClickOnToggle}
                    isSidebarClose={isSidebarClose}
                    setIsSidebarClose={(isShow) => setIsSidebarClose(isShow)}
                    activeHandler={() => detectListHandler}
                    isActive={active === 10}
                  />
                </div>
              </div>
            </ul>
          </div>
        </aside>
        <div
          className={[
            classes["sidebar__mainContent"],
            isSidebarClose && classes["margin-left-0"],
          ].join(" ")}
          onMouseEnter={() => {
            if (!isClickOnToggle) {
              if (sidebarAside?.current?.offsetWidth == 300)
                setIsSidebarClose(true);
              setActive(null);
            }
          }}
        >
          <div className={classes["content-wrapper"]}>{children}</div>
        </div>
      </div>
    </>
  );
};

export default SidebarSkeleton;
