import React from "react";
import ReactSelect, { components } from "react-select";
import classes from "./PageDropdown.module.css";
import PropTypes from "prop-types";
import { MdOutlineArrowDropDown, MdOutlineArrowDropUp } from "react-icons/md";

export const PageDropdown = ({
  options,
  label,
  labelTwo,
  customStyle,
  disabled,
  value,
  setter,
  noBorder,
  placeholder,
  isMulti,
  style,
  leftIcon,
  Components,
  labelClassName,
  indicatorColor = "var(--medium-gray)",
  optionLabel,
  optionValue,
  selectRef,
  isSearchable = false,
  dropdownIndicator = true,
  ...props
}) => {
  const PageDropdown = ({ selectProps, ...props }) => {
    const { menuIsOpen } = selectProps;

    return (
      <components.DropdownIndicator {...props}>
        {menuIsOpen ? (
          <MdOutlineArrowDropUp size={20} color={indicatorColor} />
        ) : (
          <MdOutlineArrowDropDown size={20} color={indicatorColor} />
        )}
      </components.DropdownIndicator>
    );
  };

  const dropDownStyle = {
    control: (styles, { isFocused, isDisabled }) => ({
      ...styles,
      backgroundColor: isDisabled ? "#cccccc" : "#ffff",
      padding: "6px 0px 6px 6px",
      borderRadius: "8px",
      color: "var(--text-color-black)",
      boxShadow: "0px 0px 1px 0px #32324733",

      fontFamily: "Inter-regular",
      fontSize: 15,
      letterSpacing: "1.4",
      cursor: "pointer",
      border: "none",
      ...customStyle,

      ":hover": {
        ...styles[":hover"],
        borderColor: "var(--primary-color)",
        borderRadius: "8px",
      },
      ":placeholder": {
        ...styles[":placeholder"],
        color: "var(--text-color-black)",
      },
      ":active": {
        ...styles[":active"],
        borderColor: "var(--primary-color)",
      },
    }),

    menuList: (provided) => ({
      ...provided,
      borderRadius: "8px", // Set border radius as desired
    }),
    menu: (styles) => ({
      ...styles,
      // position: "relative",
      // zIndex: 9999999, // Set a higher z-index value
      padding: "10px 8px",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", // Add box shadow if needed
      borderRadius: "8px",
      ...styles,
      position: "absolute",
      top: "130%",
      left: "-25%",
      zIndex: 99999,

      width: "100%",
      marginTop: "1rem",
    }),
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "var(--placeholder-color)",
      };
    },

    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected && "var(--grainsboro)",
        color: isSelected && "#000",
        padding: "8px",
        fontFamily: "Inter-regular",
        fontSize: 15,
        borderRadius: "8px",

        ":active": {
          ...styles[":active"],
        },
        ":hover": {
          ...styles[":hover"],
          color: "var(--black)",
          backgroundColor: "var(--gainsboro)",
          cursor: "pointer",
        },
      };
    },

    multiValue: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: "var(--primary-color)",
        borderRadius: "8px",
        padding: "4px 8px",
        fontFamily: "Poppins-regular",
      };
    },
    singleValue: (styles, { data }) => {
      return {
        ...styles,
        fontSize: 15,
        fontFamily: "Inter-regular",
        textAlign: "center",
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: "#fff",
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      fontSize: 12,
      color: "#fff",
      ":hover": {
        color: "#000",
      },
    }),
  };
  return (
    <div className={`${[classes.Container].join(" ")}`}>
      <style jsx>{`
        .dropdownContainer {
          position: relative; /* Ensure the container has a positioning context */
          z-index: 9999; /* Set a higher z-index value */
          box-shadow: 0px 0px 1px 0px #32324733;

          box-shadow: 0px 1px 2px 0px #32324714;
        }
        .DropdownOptionContainer__value-container {
          padding: 0;
        }
        .DropdownOptionContainer__menu {
          margintop: 100px;
          width: fit-content;
          margin: 0px;
          border: 0px;
        }
        .DropdownOptionContainer__single-value {
          color: var(--text-color-black);
        }
        .DropdownOptionContainer__menu {
          box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
        }
      `}</style>
      {label && (
        <label
          htmlFor={`dropdown${label}`}
          className={`${[
            classes.label,
            labelClassName && labelClassName,
            disabled && classes.disabled,
          ].join(" ")}`}
        >
          {label}
        </label>
      )}

      <div className={`${[classes.dropdownContainer].join(" ")}`}>
        <ReactSelect
          inputId={`dropdown${label}`}
          value={value}
          onChange={(e) => {
            setter(e);
          }}
          className={`${[classes.reactSelect].join(" ")}`}
          isMulti={isMulti}
          isDisabled={disabled}
          placeholder={placeholder}
          options={options}
          styles={{ ...dropDownStyle, ...style }}
          isClearable={false}
          isSearchable={isSearchable}
          classNamePrefix={"DropdownOptionContainer"}
          components={{
            IndicatorSeparator: () => null,
            DropdownIndicator: (e) => {
              if (dropdownIndicator) return PageDropdown(e);
              return null;
            },
            ...Components,
          }}
          getOptionLabel={(option) => {
            return optionLabel ? option[optionLabel] : option.label;
          }}
          getOptionValue={(option) =>
            optionValue ? option[optionValue] : option.value
          }
          ref={selectRef}
          {...props}
        />
        {leftIcon && <div className={classes.leftIconBox}>{leftIcon}</div>}
      </div>
    </div>
  );
};

PageDropdown.propTypes = {
  options: PropTypes.array.isRequired,
  label: PropTypes.string,
  labelTwo: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.object.isRequired,
  setter: PropTypes.object,
  disabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  customStyle: PropTypes.object,
  style: PropTypes.object,
  Components: PropTypes.object,
  labelClassName: PropTypes.string,
};

PageDropdown.defaultProps = {
  placeholder: "sdsad",
  value: "aaaa",
  disabled: false,
  isMulti: false,
  options: [],
  Components: {},
};
