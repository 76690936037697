import classes from "./NoData.module.css";
import { IoSearch } from "react-icons/io5";
const NoData = ({ title = "No Data Found" }) => {
  return (
    <div className={classes.wrapper}>
      <div className={classes.noData}>
        <div className={classes.iconWrapper}>
          <IoSearch size={36} color="var(--main-color)" />
        </div>
        <p>{title}</p>
      </div>
    </div>
  );
};

export default NoData;
