import React, { useState } from "react";
import classes from "./Step3.module.css";
import { Row, Col } from "react-bootstrap";
import validator from "validator";
import { Input } from "../../../../components/Input";
import Phone from "../../../../components/Phone";
import FormWrapper from "../../../../components/FormWrapper";

const Step3 = ({ data, setData, setIsValidPhone }) => {
  return (
    <div>
      <FormWrapper>
        <Row>
          <Input
            label={"Hospital/Institution Name "}
            value={data?.hospitalInstitutionName}
            setter={(val) =>
              setData((prev) => ({
                ...prev,
                hospitalInstitutionName: val,
              }))
            }
            placeholder={"Enter Hospital Name"}
            labelStyle={{ fontWeight: 600 }}
            required={data?.hospitalInstitutionName}
          />
        </Row>
        <Row>
          <Col xl={6} md={6} sm={12}>
            <Input
              type={"number"}
              label={`Hospital Phone Number `}
              placeholder={`Enter Hospital Phone Number`}
              value={data?.hospitalContactNo}
              setter={(val) => {
                setIsValidPhone(validator?.isMobilePhone(val));
                if (val.length === 1 && val === "0") {
                  return;
                } else {
                  setData((prevData) => {
                    return { ...prevData, hospitalContactNo: val };
                  });
                }
              }}
              labelStyle={{ fontWeight: 600 }}
              required={data?.hospitalInstitutionName}
              salutation={"+92"}
              salutationStyles={{ width: "15%" }}
            />
          </Col>
          <Col xl={6} md={6} sm={12}>
            <Input
              type={"email"}
              label={"Hospital Email Address "}
              value={data.hospitalEmailAddress}
              setter={(val) =>
                setData((prev) => ({
                  ...prev,
                  hospitalEmailAddress: val,
                }))
              }
              placeholder={"Enter Hospital Email Address"}
              labelStyle={{ fontWeight: 600 }}
              required={data?.hospitalInstitutionName}
            />
          </Col>
        </Row>

        <Row>
          <Input
            label={"Hospital Address "}
            value={data.hospitalAddress}
            setter={(val) =>
              setData((prev) => ({
                ...prev,
                hospitalAddress: val,
              }))
            }
            placeholder={"Enter Hospital Address"}
            labelStyle={{ fontWeight: 600 }}
            required={data?.hospitalInstitutionName}
          />
        </Row>
      </FormWrapper>
    </div>
  );
};

export default Step3;
