import React from "react";
import classes from "./FormWrapper.module.css";

const FormWrapper = ({ className, handlerFunction, children }) => {
  return (
    <form
      className={[
        classes["form-wrapper"],
        className,
        "form-wrapper-custom",
      ].join(" ")}
      onSubmit={handlerFunction}
    >
      {children}
    </form>
  );
};

export default FormWrapper;
