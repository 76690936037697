import React from "react";
import { Col, Modal, Row } from "react-bootstrap";
import classes from "./FacultyDetailsModal.module.css";
import Button from "../../components/Button";
import CommonCard from "../../components/CommonCard";
import { defaultProfile } from "../../assets/constant";

const FacultyDetailsModal = ({ show, setShow, data }) => {
  return (
    <>
      <style>{`
      .modal-lg {
        --bs-modal-width: 600px;
      }
      .modal-content{
        border-radius: 16px;
      }
    `}</style>
      <Modal
        show={show}
        onHide={setShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <CommonCard className={classes["extraCardStyles"]}>
          <div className={classes["heading-wrapper"]}>
            <h1>Consultant Details</h1>
          </div>
          <div className={classes["profile-img"]}>
            <img
              src={
                data?.Image
                  ? require(`../../assets/images/${data?.Image}`)
                  : defaultProfile
              }
              alt=""
            />
          </div>
          <div className={classes["details-wrapper"]}>
            <Row>
              <Col lg={5} className={classes["detail-title"]}>
                <h2>Doctor Name</h2>
              </Col>
              <Col lg={7} className={classes["detail-description"]}>
                <h3 className={classes["name-h3"]}>{data?.Name}</h3>
              </Col>
            </Row>
            <Row>
              <Col lg={5} className={classes["detail-title"]}>
                <h2>Designation</h2>
              </Col>
              <Col lg={7} className={classes["detail-description"]}>
                <h3>{data?.Designation}</h3>
              </Col>
            </Row>
            <Row>
              <Col lg={5} className={classes["detail-title"]}>
                <h2>Qualification</h2>
              </Col>
              <Col lg={7} className={classes["detail-description"]}>
                <h3>{data?.Qualification}</h3>
              </Col>
            </Row>
            <Row>
              <Col lg={5} className={classes["detail-title"]}>
                <h2>City</h2>
              </Col>
              <Col lg={7} className={classes["detail-description"]}>
                <h3>{data?.City}</h3>
              </Col>
            </Row>
            <Row>
              <Col lg={5} className={classes["detail-title"]}>
                <h2>Specializaion</h2>
              </Col>
              <Col lg={7} className={classes["detail-description"]}>
                <h3>{data?.SpecializationSection}</h3>
              </Col>
            </Row>
          </div>
          <div className={classes["action-wrapper"]}>
            <Button
              label="Close"
              type="button"
              className={classes["cancel-button"]}
              onClick={setShow}
            />
          </div>
        </CommonCard>
      </Modal>
    </>
  );
};

export default FacultyDetailsModal;
