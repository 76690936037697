import React, { useState, useEffect } from "react";
import { logo } from "../../../assets/constant";
import classes from "./Login.module.css";
import { Col, Form, Row } from "react-bootstrap";
import Button from "../../../components/Button";
import { Input } from "../../../components/Input";
import { Link } from "react-router-dom";
import { Post } from "../../../AxiosCRUDFunctions";
import { BaseURL, apiHeader } from "../../../config";
import { toast } from "react-toastify";
import { login } from "../../../store/authSlice";
import { useDispatch } from "react-redux";
import Loader from "../../../components/Loader";
import CommonCard from "../../../components/CommonCard";
import FormWrapper from "../../../components/FormWrapper";
import MainPopUp from "../../../components/MainPopUp";
import { popup, popupMini } from "../../../assets/constant";

const Login = () => {
  const [imageFlag, setImageFlag] = useState(false);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(true);
  const [form, setForm] = useState({
    userName: null,
    password: null,
  });

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (form?.userName && form?.password) {
      const data = { userName: form?.userName, password: form?.password };
      const response = await Post(BaseURL("SignUp/Login"), data, apiHeader());
      if (
        response?.data?.body !== null &&
        response?.data?.headers?.error === 200
      ) {
        dispatch(login(response?.data?.body));
        toast.success("Login Successfully");
      } else {
        toast.error(response?.data?.headers?.message);
      }
    } else {
      toast.error("Please Enter Credentials");
    }
    setLoading(false);
  };
  const handleResize = () => {
    if (window.innerWidth < 768) {
      setImageFlag(true);
    } else {
      setImageFlag(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);
  return (
    <>
      <MainPopUp show={showPopup} setShow={() => setShowPopup(false)}>
        <img
          className={classes["image"]}
          src={imageFlag ? popupMini : popup}
          alt=""
        />
      </MainPopUp>
      {loading ? (
        <Loader />
      ) : (
        <div className={classes["main-wrapper"]}>
          <CommonCard className={classes["extraCardStyles"]}>
            <span className={classes["brand-logo"]}>
              <img src={logo && logo} alt="logo" />
            </span>
            <div className={classes["main-heading"]}>
              <h1>Welcome To NIBD HealthCare Referral Network</h1>
              <p>
                Let's Join Hands to Prevent and Cure Cancers & Blood Diseases{" "}
              </p>
            </div>
            <FormWrapper handlerFunction={handleLogin}>
              <Row>
                <Input
                  label={"Email or Phone Number"}
                  value={form.userName}
                  setter={(val) =>
                    setForm((prev) => ({ ...prev, userName: val }))
                  }
                  placeholder={"Enter Email or Phone Number"}
                  required={true}
                  message={"Phone No: +92 XXX XXXXXXX"}
                />
              </Row>
              <Row>
                <Input
                  label={"Password"}
                  value={form.password}
                  setter={(val) =>
                    setForm((prev) => ({ ...prev, password: val }))
                  }
                  placeholder={"Enter Password"}
                  type={"password"}
                  required={true}
                />
                <div className={classes["extra-wrapper"]}>
                  <div className={classes["rememberMe"]}>
                    <input
                      type="checkbox"
                      id="remember"
                      name="remember"
                      // value={form?.remember}
                    />
                    <label for="remember">Remember Me</label>
                  </div>
                  <div>
                    <span className={classes["forgetPass"]}>
                      <Link to={"forgetPassword"}>Forget Password</Link>
                    </span>
                  </div>
                </div>
              </Row>
              <Button
                label="Sign In"
                type="submit"
                className={classes["signIn-button"]}
              />
            </FormWrapper>
            <span className={classes["or-span"]}>or</span>
            <div className={classes["register-wrapper"]}>
              <span>Don't have an account?</span>
              <span className={classes["forgetPass"]}>
                <Link to="signup">Register</Link>
              </span>
            </div>
          </CommonCard>
        </div>
      )}
    </>
  );
};

export default Login;
