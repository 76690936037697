import classes from "./Phone.module.css";
import PhoneInput from "react-phone-input-2";
import { useRef } from "react";
import { Form } from "react-bootstrap";
const Phone = ({
  value,
  className,
  setValue,
  label,
  labelStyles,
  placeholder,
  inputProps,
  initialCountry = "pk",
  required,
  inputStyle,
  inputClass,
  disableCountryCode = false,
  markAsOptional = false,
  disabled = false,
  ref,
  defaultCode,
  ...rest
}) => {
  const phoneInputRef = useRef();
  return (
    <>
      <style>
        {`
      .phoneInputReadOnly .flag > .arrow{
        display:none;
      }
      .special-label{
        display: none
      }
      .form-control{
        font-size: 16px;
        color: #000;
        letter-spacing: 1.4px;
        padding: 15px 20px;
        width: 100%;
        outline: none;
        border: none;
        transition: none
      }
      .form-control:focus{
        border: none;
        box-shadow: none;
      }
      `}
      </style>
      <div className={`${[classes.Container, "border-radius"].join(" ")}`}>
        {label && (
          <label
            htmlFor={`input${label}`}
            className={`mb8px  ${[
              classes.labelText,
              disabled && classes.disabled,
            ].join(" ")}`}
            style={{ ...labelStyles }}
          >
            {label}
            {required && <span style={{ color: "red" }}> *</span>}
          </label>
        )}
        <div
          className={[
            className,
            classes["phoneInput-wrapper"],
            classes["inputPassContainer"],
            disabled && classes["phoneInpViewOnly"],
          ].join(" ")}
        >
          {defaultCode && (
            <input
              value={defaultCode}
              readOnly={true}
              id={`input${label}`}
              className={` ${[classes.inputBoxSalutation].join(" ")}`}
            />
          )}
          <PhoneInput
            ref={phoneInputRef}
            disabled={disabled}
            required={required}
            country={initialCountry}
            className={[classes["phone-field"], "  p-0"].join(" ")}
            placeholder={placeholder}
            value={value}
            label={label}
            onChange={setValue}
            inputProps={{
              ...inputProps,
            }}
            {...rest}
            // inputClass={inputClass}
          />
        </div>
      </div>
    </>
  );
};

export default Phone;
