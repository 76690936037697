import classes from "./StatusIndicator.module.css";
const StatusIndicator = ({ title, bgColor }) => {
  return (
    <div className={classes.wrapper}>
      <span
        style={{ background: bgColor || "var(--coral)" }}
        className={classes.indicatorFlag}
      ></span>
      <p className={classes.title}>{title}</p>
    </div>
  );
};

export default StatusIndicator;
