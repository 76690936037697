import moment from "moment";
import { defaultProfile } from "../assets/constant";
import "moment-duration-format";

export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const CreateFormData = (data) => {
  const formData = new FormData();
  for (let key in data) {
    formData.append(key, data[key]);
  }
  return formData;
};

export const formRegEx = /([a-z])([A-Z])/g;
export const formRegExReplacer = "$1 $2";

export var recordsLimit = 10;

export const dateDifference = (date) => {
  let duration = moment.duration(moment().diff(date));
  let daysDiff = duration.asDays();
  return daysDiff;
};

export const capitalizeFirstLetter = (l) =>
  l.charAt(0).toUpperCase() + l.slice(1);

export const formatPhoneNumber = (phoneNumber) => {
  const pattern = /^\+923\d{9}$/;
  if (pattern.test(phoneNumber)) {
    let formattedNumber = phoneNumber.replace(
      /\+(\d{2})\s*(\d{3})\s*(\d{7})/,
      "+$1 ($2) $3"
    );
    return formattedNumber;
  } else {
    return null;
  }
};

export const setAvatar = (gender) => {
  return gender === "Male" ? defaultProfile : "";
};

export const calculateAgeByDob = (dob) => {
  const birthDate = moment(dob);
  const now = moment();
  const years = now.diff(birthDate, "years");
  birthDate.add(years, "years");

  const months = now.diff(birthDate, "months");
  birthDate.add(months, "months");

  const days = now.diff(birthDate, "days");

  return `${years} years, ${months} months, and ${days} days`;
};
