import React, { useState } from "react";
import classes from "./Notification.module.css";
import Navbar from "../../../components/Navbar";
import Loader from "../../../components/Loader";
import { Col, Row } from "react-bootstrap";
import { Input } from "../../../components/Input";
import { CiSearch, CiLocationOn } from "react-icons/ci";
import { VscListFlat } from "react-icons/vsc";
import Dropdown from "../../../components/Dropdown";
import CommonCard from "../../../components/CommonCard";
import NotificationComponent from "../../../components/NotificationComponent";

const Notification = () => {
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [sortByList, setSortByList] = useState([]);
  const [notificationsList, setNotificationsList] = useState([
    {
      image: "",
      message:
        "Dear Doctor, We would like to inform you about a new referral criteria document that has been implemented by the hospital. Please find the document attached for your reference.",
      time: "1 month ago",
    },
    {
      image: "",
      message:
        "Dear Doctor, We would like to inform you about a new referral criteria document that has been implemented by the hospital. Please find the document attached for your reference.",
      time: "1 month ago",
    },
    {
      image: "",
      message:
        "Dear Doctor, We would like to inform you about a new referral criteria document that has been implemented by the hospital. Please find the document attached for your reference.",
      time: "1 month ago",
    },
  ]);

  const notificationHandler = () => {
    console.log("working");
  };

  return (
    <>
      <Navbar title={"Notifications"} />
      {loading ? (
        <Loader />
      ) : (
        <>
          {/* <div className={classes["filters-wrapper"]}>
            <Row
              style={{
                width: "98%",
                margin: "0px 5px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Col
                lg={6}
                md={6}
                sm={12}
                style={{
                  marginBottom: "10px",
                  padding: "0px",
                }}
              >
                <Input
                  placeholder={"Search by Name"}
                  leftIcon={
                    <CiSearch
                      size={20}
                      style={{
                        marginTop: -5,
                        color: "var(--placeholder-color)",
                      }}
                    />
                  }
                  extraInputBox={classes["extraInputBox"]}
                  setter={setSearch}
                  value={search}
                />
              </Col>
              <Col
                xl={4}
                lg={3}
                md={3}
                className="d-xl-block d-lg-block d-md-block d-sm-none"
              ></Col>
              <Col xl={2} lg={3} md={3} sm={6} style={{ padding: "0px" }}>
                <Dropdown
                  value={sortBy}
                  setter={(val) => setSortBy((prev) => val)}
                  placeholder={
                    <>
                      <VscListFlat
                        size={15}
                        style={{
                          color: "var(--placeholder-color)",
                        }}
                      />{" "}
                      Sort By:
                    </>
                  }
                  required={true}
                  labelStyle={{ fontWeight: 600 }}
                  options={sortByList?.map((data) => {
                    return {
                      label: data?.stateName,
                      value: data?.stateId,
                    };
                  })}
                  isSearchable={true}
                />
              </Col>
            </Row>
          </div>
          <div className={classes["main-div"]}>
            <CommonCard className={classes["extraStyle"]}>
              {notificationsList?.length ? (
                notificationsList?.map((item, index) => (
                  <NotificationComponent
                    data={item}
                    handler={notificationHandler}
                  />
                ))
              ) : (
                <p className={classes["no-noti"]}>No Notifications Found</p>
              )}
            </CommonCard>
          </div> */}
        </>
      )}
    </>
  );
};

export default Notification;
