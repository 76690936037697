import React, { useState, useEffect } from "react";
import classes from "./Step5.module.css";
import FormWrapper from "../../../../components/FormWrapper";
import { Row, Col } from "react-bootstrap";
import { maleAvatar, femaleAvatar } from "../../../../assets/constant";

const Step5 = ({ setAvatar, selectedAvatar }) => {
  return (
    <>
      <FormWrapper>
        <Row style={{ display: "flex", alignItems: "center", width: "100%" }}>
          <Col
            xl={6}
            md={6}
            sm={12}
            onClick={() => setAvatar(maleAvatar, "Male")}
            className={[
              selectedAvatar === "Male" && classes["selected"],
              classes["imageWrapper"],
            ].join(" ")}
          >
            <img src={maleAvatar} alt="male" />
            <p>Male</p>
          </Col>
          <Col
            xl={6}
            md={6}
            sm={12}
            onClick={() => setAvatar(femaleAvatar, "Female")}
            className={[
              selectedAvatar === "Female" && classes["selected"],
              classes["imageWrapper"],
            ].join(" ")}
          >
            <img src={femaleAvatar} alt="female" />
            <p>Female</p>
          </Col>
        </Row>
      </FormWrapper>
    </>
  );
};

export default Step5;
