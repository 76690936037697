import React, { useEffect, useState } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import classes from "./MainPopUp.module.css";
import CommonCard from "../CommonCard";
import { Modal } from "react-bootstrap";
import Button from "../Button";
import { LuZoomIn, LuZoomOut } from "react-icons/lu";
import { MdDownload } from "react-icons/md";

const MainPopUp = ({ show, setShow, children, ...props }) => {
  return (
    <>
      <style>{`
  .modal-content{
    border-radius: 16px;
  }
  .modal-lg {
        --bs-modal-width: 600px;
      }
`}</style>
      <Modal
        show={show}
        onHide={setShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <CommonCard className={classes["extraCardStyles"]}>
          <span className={classes["cross-icon"]} onClick={setShow}>
            x
          </span>
          <TransformWrapper
            initialScale={1}
            initialPositionX={0}
            initialPositionY={0}
            maxScale={1.65}
          >
            {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
              <React.Fragment>
                <div className={classes["btn-wrapper"]}>
                  <Button onClick={() => zoomIn()} className={classes["btn"]}>
                    <LuZoomIn />
                  </Button>
                  <Button onClick={() => zoomOut()} className={classes["btn"]}>
                    <LuZoomOut />
                  </Button>
                  {props?.download && (
                    <Button
                      onClick={props.downloadPdf}
                      className={classes["btn"]}
                    >
                      <MdDownload />
                    </Button>
                  )}
                </div>
                <TransformComponent>{children}</TransformComponent>
              </React.Fragment>
            )}
          </TransformWrapper>
        </CommonCard>
      </Modal>
    </>
  );
};

export default MainPopUp;
