import { Form } from "react-bootstrap";

const FormCheckbox = ({ id, setter, className, ...rest }) => {
  return (
    <Form.Check
      id={id}
      reverse
      onChange={(e) => {
        setter(e.target.checked);
      }}
      {...rest}
      type={`checkbox`}
      className={className}
    />
  );
};

export default FormCheckbox;
