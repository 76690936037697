import React, { useState } from "react";
import classes from "./Step2.module.css";
import { Col, Row } from "react-bootstrap";
import validator from "validator";
import { Input } from "../../../../components/Input";
import Dropdown from "../../../../components/Dropdown";
import Phone from "../../../../components/Phone";
import FormWrapper from "../../../../components/FormWrapper";

const Step2 = ({ data, setData, province, cities, setIsValidPhone }) => {
  return (
    <div>
      <FormWrapper>
        <Row>
          <Col xl={6} md={6} sm={12}>
            <Input
              type={"email"}
              label={"Email Address "}
              value={data.emailAddress}
              setter={(val) =>
                setData((prev) => ({
                  ...prev,
                  emailAddress: val,
                }))
              }
              placeholder={"Enter Email Address"}
              required={true}
              labelStyle={{ fontWeight: 600 }}
            />
          </Col>
          <Col xl={6} md={6} sm={12}>
            <Input
              type={"number"}
              label={`Phone Number `}
              placeholder={`Enter Phone Number`}
              value={data?.phoneNo}
              setter={(val) => {
                setIsValidPhone(validator?.isMobilePhone(val));
                if (val.length === 1 && val === "0") {
                  return;
                } else {
                  setData((prev) => ({
                    ...prev,
                    phoneNo: val,
                  }));
                }
              }}
              labelStyle={{ fontWeight: 600 }}
              required={true}
              salutation={"+92"}
              salutationStyles={{ width: "15%" }}
            />
          </Col>
        </Row>
        <Row>
          <Col xl={6} md={6} sm={12}>
            <Input
              label={"Password "}
              value={data.password}
              setter={(val) => setData((prev) => ({ ...prev, password: val }))}
              placeholder={"Enter Password"}
              type={"password"}
              labelStyle={{ fontWeight: 600 }}
              required={true}
            />
          </Col>
          <Col xl={6} md={6} sm={12}>
            <Input
              label={"Confirm Password "}
              value={data.confirmPassword}
              setter={(val) =>
                setData((prev) => ({ ...prev, confirmPassword: val }))
              }
              placeholder={"Confirm Password"}
              type={"password"}
              labelStyle={{ fontWeight: 600 }}
              required={true}
            />
          </Col>
        </Row>
        <Row>
          <Col xl={6} md={6} sm={12}>
            <Dropdown
              label={"Province "}
              value={data?.province}
              setter={(val) =>
                setData((prev) => ({
                  ...prev,
                  province: val,
                }))
              }
              placeholder={"Search Province"}
              required={true}
              labelStyle={{ fontWeight: 600 }}
              options={province?.map((data) => {
                return {
                  label: data?.stateName,
                  value: data?.stateId,
                };
              })}
              isSearchable={true}
            />
          </Col>
          <Col xl={6} md={6} sm={12}>
            <Dropdown
              label={"City "}
              value={data?.city}
              setter={(val) =>
                setData((prev) => ({
                  ...prev,
                  city: val,
                }))
              }
              placeholder={"Search City"}
              required={true}
              labelStyle={{ fontWeight: 600 }}
              options={cities?.map((data) => {
                return {
                  label: data?.cityName,
                  value: data?.cityId,
                };
              })}
              isSearchable={true}
            />
          </Col>
        </Row>
        <Row>
          <Input
            label={"Address "}
            value={data.address}
            setter={(val) =>
              setData((prev) => ({
                ...prev,
                address: val,
              }))
            }
            placeholder={"Enter Address"}
            labelStyle={{ fontWeight: 600 }}
          />
        </Row>
      </FormWrapper>
    </div>
  );
};

export default Step2;
