import classes from "./PaginationWrapper.module.css";
const PaginationWrapper = ({ children, limit, totalCount }) => {
  return (
    <div
      className={[classes.wrapper, "flex_grid_justify-content-between"].join(
        " "
      )}
    >
      <p>{`Showing ${limit} items out of ${totalCount} results found`}</p>
      {children}
    </div>
  );
};

export default PaginationWrapper;
