import React from "react";
import CommonCard from "../../CommonCard";
import { Col, Row } from "react-bootstrap";
import classes from "./CarouselCard1.module.css";

const CarouselCard1 = () => {
  const item = {
    title: "Bone Marrow Transplant",
    subTitle: "Both Paediatric & Adult Patients",
    description: [
      "Leukaemias",
      "B-Thalassemia",
      "Aplastic Anaemia (Both Inherited and Acquired)",
      "Immunodeficiency Syndromes",
      "Myeloma",
      "Lymphoma",
    ],
    image: "carousel1.png",
  };
  return (
    <CommonCard className={classes["cardStyle"]}>
      <Row>
        <Col
          xl={5}
          lg={12}
          md={12}
          sm={12}
          className={[classes["imageWrapper"], classes["h-100"]].join(" ")}
        >
          <img src={require(`../../../assets/images/${item?.image}`)} alt="" />
        </Col>
        <Col
          xl={7}
          lg={12}
          md={12}
          sm={12}
          className={[classes["description-wrapper"], classes["h-100"]].join(
            " "
          )}
        >
          <div className={classes["main-heading"]}>
            <h1>{item?.title}</h1>
            <span> ({item?.subTitle})</span>
          </div>
          <div className={classes["details"]}>
            <ul>
              {item?.description?.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>
        </Col>
      </Row>
    </CommonCard>
  );
};

export default CarouselCard1;
