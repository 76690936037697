import React from "react";
import classes from "./CommonCard.module.css";

const CommonCard = ({ className, children, onClick }) => {
  return (
    <div
      className={[classes["commonCard"], className].join(" ")}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default CommonCard;
