import React, { useRef, useState } from "react";
import CommonCard from "../CommonCard";
import { PageDropdown } from "../PageDropdown";
import classes from "./TableHeaderFilters.module.css";
import { Input } from "../Input";
import { IoSearch } from "react-icons/io5";
const TableHeaderFilter = ({ filterActions }) => {
  const { limit, setLimit, search, setSearch } = filterActions;
  const ref = useRef();

  return (
    <>
      <style>{`
        .pagination-wraper .css-1fdsijx-ValueContainer {
          padding:0 !important;
        }
        
        `}</style>
      <div className={classes["table__header"]}>
        <h2 className={classes["table__title"]}>List of Referred Patient</h2>

        <div className={classes["show-pages-and-colummns"]}>
          <div className={classes["page-indicator-wrapper"]}>
            <p className={classes.pageIndicator}>Results per page</p>

            <CommonCard
              className={[classes.pageValue__wrapper, "pagination-wraper"].join(
                " "
              )}
            >
              <PageDropdown
                style={{ height: "34px" }}
                setter={setLimit}
                value={limit}
                dropdownIndicator={false}
                options={[
                  { label: 5, value: "5" },
                  { label: 10, value: "10" },
                  { label: 25, value: "25" },
                  { label: 50, value: "50" },
                ]}
                customStyle={{ padding: "0px", width: "32px" }}
              />
            </CommonCard>
          </div>
          <Input
            value={search}
            setter={setSearch}
            type={`search`}
            leftIcon={
              <IoSearch
                color="var(--silver)"
                size={18}
                className={classes["adjust-action"]}
              />
            }
            inputStyle={{ padding: "7px 30px" }}
            leftIconStyles={{
              marginLeft: "10px",
              left: "-1.6%",
              marginBottom: "1rem",
            }}
          />
        </div>
      </div>
    </>
  );
};

export default TableHeaderFilter;
