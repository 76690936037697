import React, { useState } from "react";
import PropTypes from "prop-types";
import classes from "./Input.module.css";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

export const Input = ({
  type,
  label,
  label2, // sub label
  value, // input value
  setter, //setValue
  noBorder,
  placeholder,
  disabled,
  parentCustomStyle, //Main Div Inline Style
  customStyle, //Input Container inline Style
  inputStyle, //Input inline Style
  labelStyle, //Label inline Style
  error, // Show Error Boolean
  errorText, // Error Text
  leftIcon, // Icon For Input
  rightIcon,
  onKeyPress,
  regexType,
  required,
  message,
  salutation,
  salutationStyles,
  textArea,
  leftIconStyles,
  leftIconClass,
  extraInputBox,
  ...props
}) => {
  const [passwordToggle, setPassowrdToggle] = useState(false);
  let inputContainerStyleObject = Object.assign(
    {},
    error && { border: `1px solid red ` },
    leftIcon && { paddingLeft: "50px" }
  );
  return (
    <>
      <div
        className={`${[classes.Container, "border-radius"].join(" ")}`}
        style={{ ...parentCustomStyle }}
      >
        {label && (
          <label
            htmlFor={`input${label}`}
            className={`mb8px  ${[
              classes.labelText,
              disabled && classes.disabled,
            ].join(" ")}`}
            style={{ ...labelStyle }}
          >
            {label} {label2 && label2}
            {required && <span style={{ color: "red" }}>*</span>}
          </label>
        )}
        <div
          className={`${[classes.inputPassContainer, "border-radius"].join(
            " "
          )}`}
          style={{ ...customStyle }}
        >
          {leftIcon && (
            <div
              className={[classes.leftIconBox, leftIconClass].join(" ")}
              style={{ ...leftIconStyles }}
            >
              {leftIcon}
            </div>
          )}
          {salutation && (
            <input
              value={salutation}
              readOnly={true}
              id={`input${label}`}
              className={` ${[
                classes.inputBoxSalutation,
                noBorder && classes.noBorder,
              ].join(" ")}`}
              style={{
                ...inputContainerStyleObject,
                ...salutationStyles,
                ...inputStyle,
              }}
            />
          )}

          {textArea ? (
            <textarea
              value={value}
              onKeyPress={(e) => {
                if (onKeyPress) {
                  ["Enter", "NumpadEnter"].includes(e.code) && onKeyPress();
                }
              }}
              onChange={(e) => {
                if (type == "number") {
                  // setter(e?.target?.value?.replace("0", ""));
                  setter(e.target.value);
                } else {
                  setter(e.target.value);
                }
              }}
              disabled={disabled}
              placeholder={placeholder}
              type={passwordToggle == true ? "text" : type}
              id={`input${label}`}
              className={` ${[
                classes.inputBox,
                noBorder && classes.noBorder,
              ].join(" ")}`}
              style={{ ...inputContainerStyleObject, ...inputStyle }}
              // onBlur={() => {
              //   if (type !== "number") {
              //     setter(value?.trim());
              //   }
              // }}
              {...props}
            />
          ) : (
            <input
              value={value}
              onKeyPress={(e) => {
                if (onKeyPress) {
                  ["Enter", "NumpadEnter"].includes(e.code) && onKeyPress();
                }
              }}
              onChange={(e) => {
                if (type == "number") {
                  // setter(e?.target?.value?.replace("0", ""));
                  setter(e.target.value);
                } else {
                  setter(e.target.value);
                }
              }}
              disabled={disabled}
              placeholder={placeholder}
              type={passwordToggle == true ? "text" : type}
              id={`input${label}`}
              className={` ${[
                classes.inputBox,
                extraInputBox,
                noBorder && classes.noBorder,
              ].join(" ")}`}
              style={{ ...inputContainerStyleObject, ...inputStyle }}
              // onBlur={() => {
              //   if (type !== "number") {
              //     setter(value?.trim());
              //   }
              // }}
              {...props}
            />
          )}
          {rightIcon && <div className={classes.rightIcon}>{rightIcon}</div>}
          {type == "password" && passwordToggle == false && (
            <span
              className={[classes["pe-12"], classes["eye-icon-wrapper"]].join(
                " "
              )}
              onClick={() => {
                setPassowrdToggle((currentState) => !currentState);
              }}
            >
              <AiFillEye fontSize={20} color="#0059A9" />
            </span>
          )}
          {type == "password" && passwordToggle && (
            <span
              className={[classes["pe-12"], classes["eye-icon-wrapper"]].join(
                " "
              )}
              onClick={() => {
                setPassowrdToggle((currentState) => !currentState);
              }}
            >
              <AiFillEyeInvisible fontSize={20} color="#0059A9" />
            </span>
          )}
        </div>
        {error && (
          <p className={`mt-2 ${[classes.errorText].join(" ")}`}>{errorText}</p>
        )}
        {message && (
          <p className={`mt-2 ${[classes.messageText].join(" ")}`}>{message}</p>
        )}
      </div>
    </>
  );
};

Input.propTypes = {
  type: PropTypes.oneOf.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  setter: PropTypes.string,
  noBorder: PropTypes.bool,
  disabled: PropTypes.bool,
  customStyle: PropTypes.string,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  messageText: PropTypes.string,
  label2: PropTypes.string,
};

Input.defaultProps = {
  type: "text",
  placeholder: "Search Here",
  value: "",
  noBorder: false,
  disabled: false,
  error: false,
  errorText: "An error has occurred, check your details!",
};
