import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import Login from "./pages/auth/Login";
import Signup from "./pages/auth/Signup";
import DashboardLayout from "../src/layouts/DashboardLayout";
import Dashboard from "./pages/user/Dashboard";
import Account from "../src/pages/user/Account";
import NotFound from "./pages/user/NotFound";
import ForgetPassword from "./pages/auth/ForgetPassword";
import UpdatePassword from "./pages/auth/ForgetPassword/UpdatePassword";
import { useSelector } from "react-redux";
import Location from "./pages/user/Location";
import Faculty from "./pages/user/Faculty";
import Patient from "./pages/user/Patient";
import Documents from "./pages/user/Documents";
import Publication from "./pages/user/Publication";
import ReferralCriteria from "./pages/user/ReferralCriteria";
import Notification from "./pages/user/Notification";

export default function Router() {
  const token = useSelector((state) => state?.authReducer?.token);

  const authRoutes = useRoutes([
    { path: "/", element: <Login />, index: true },
    { path: "/signup", element: <Signup /> },
    { path: "/forgetPassword", element: <ForgetPassword /> },
    { path: "/forgotPassword", element: <UpdatePassword /> },
    {
      path: "*",
      element: <Navigate to="/" />,
    },
  ]);

  const userRoutes = useRoutes([
    {
      path: "/",
      element: <DashboardLayout />,
      children: [
        { path: "/", element: <Dashboard />, index: true },
        { path: "location", element: <Location /> },
        { path: "faculty", element: <Faculty /> },
        { path: "document", element: <Documents /> },
        { path: "patient", element: <Patient /> },
        { path: "referralCriteria", element: <ReferralCriteria /> },
        { path: "publication", element: <Publication /> },
        { path: "account", element: <Account /> },
        { path: "notification", element: <Notification /> },
        { path: "*", element: <NotFound /> },
      ],
    },
  ]);

  if (token) {
    return userRoutes;
  } else {
    return authRoutes;
  }
}
