import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import "@react-pdf-viewer/core/lib/styles/index.css";

import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import { PersistGate } from "redux-persist/lib/integration/react";
import store, { persistor } from "./store";
import "font-awesome/css/font-awesome.min.css";
import { Provider } from "react-redux";

const root = createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>
);
