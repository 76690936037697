import React, { useState } from "react";
import { toast } from "react-toastify";
import { Get, Post } from "../../../../AxiosCRUDFunctions";
import { lock } from "../../../../assets/constant";
import Button from "../../../../components/Button";
import { Input } from "../../../../components/Input";
import { BaseURL, apiHeader } from "../../../../config";
import classes from "./Step1.module.css";
import Loader from "../../../../components/Loader";
import CommonCard from "../../../../components/CommonCard";
import FormWrapper from "../../../../components/FormWrapper";
import { Row } from "react-bootstrap";

const Step1 = ({ setStep }) => {
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");

  const handleSendEmail = async (e) => {
    e.preventDefault();
    if (username) {
      setLoading(true);
      const response = await Get(
        BaseURL(`SignUp/ForgotPassword?UserName=${username}`),
        apiHeader()
      );
      if (response != undefined) {
        toast.success("Link has been sent Successfully");
        setLoading(false);
        setStep(2);
      } else {
        setLoading(false);
        toast.error(response?.data?.headers?.message);
      }
    } else {
      toast.error("Please enter valid Email address or Phone number");
    }
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <CommonCard className={classes["extraCardStyles"]}>
          <span className={classes["brand-logo"]}>
            <img src={lock && lock} alt="logo" />
          </span>
          <div className={classes["main-heading"]}>
            <h1>Password Reset</h1>
            <p>
              Enter your Email or Phone Number and we will send you a reset link{" "}
            </p>
          </div>
          <FormWrapper handlerFunction={handleSendEmail}>
            <Row>
              <Input
                label={"Enter Email Address or Phone Number"}
                value={username}
                setter={(val) => setUsername(val)}
                placeholder={"Enter Email or Phone Number"}
                labelStyle={{ fontWeight: 600 }}
              />
            </Row>
            <Button
              label="Send me the link"
              type="submit"
              className={classes["signIn-button"]}
            />
          </FormWrapper>
        </CommonCard>
      )}
    </>
  );
};

export default Step1;
