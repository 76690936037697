import React from "react";
import { Modal } from "react-bootstrap";
import classes from "./SuccessModal.module.css";
import Button from "../../components/Button";
import { FaCheck } from "react-icons/fa";
import CommonCard from "../../components/CommonCard";

const SuccessModal = ({ show, setShow, data, data2 = "" }) => {
  return (
    <>
      <style>{`
      .modal-content{
        border-radius: 16px;
      }
    `}</style>
      <Modal
        show={show}
        onHide={setShow}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <CommonCard className={classes["extraCardStyles"]}>
          <div className={classes["heading-wrapper"]}>
            <div className={classes["icon-wrapper"]}>
              <FaCheck size={30} color="#ffff" style={{ marginLeft: -5 }} />
            </div>
            <h1>Successfully</h1>
            <p>{data}</p>
            <p>{data2}</p>
          </div>
          <div className={classes["action-wrapper"]}>
            <Button
              label="Close"
              type="button"
              className={classes["cancel-button"]}
              onClick={setShow}
            />
          </div>
        </CommonCard>
      </Modal>
    </>
  );
};

export default SuccessModal;
