import React from "react";
import { Col, Modal, Row } from "react-bootstrap";
import classes from "./DetailsModal.module.css";
import Button from "../../components/Button";
import CommonCard from "../../components/CommonCard";
import { formatPhoneNumber } from "../../helpers";

const DetailsModal = ({ show, setShow, data }) => {
  console.log(data);
  return (
    <>
      <style>{`
      .modal-lg {
        --bs-modal-width: 600px;
      }
      .modal-content{
        border-radius: 16px;
      }
    `}</style>
      <Modal
        show={show}
        onHide={setShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <CommonCard className={classes["extraCardStyles"]}>
          <div className={classes["heading-wrapper"]}>
            <h1>Patient Details</h1>
          </div>
          <div className={classes["details-wrapper"]}>
            <Row>
              <Col
                lg={5}
                md={5}
                sm={5}
                xs={12}
                className={classes["detail-title"]}
              >
                <h2>Patient Name</h2>
              </Col>
              <Col
                lg={7}
                md={7}
                sm={7}
                xs={12}
                className={classes["detail-description"]}
              >
                <h3 className={classes["name-h3"]}>{data?.patientName}</h3>
              </Col>
            </Row>
            <Row>
              <Col
                lg={5}
                md={5}
                sm={5}
                xs={12}
                className={classes["detail-title"]}
              >
                <h2>Phone Number</h2>
              </Col>
              <Col
                lg={7}
                md={7}
                sm={7}
                xs={12}
                className={classes["detail-description"]}
              >
                <h3>{formatPhoneNumber(data?.phoneNo)}</h3>
              </Col>
            </Row>
            <Row>
              <Col
                lg={5}
                md={5}
                sm={5}
                xs={12}
                className={classes["detail-title"]}
              >
                <h2>Date of Birth</h2>
              </Col>
              <Col
                lg={7}
                md={7}
                sm={7}
                xs={12}
                className={classes["detail-description"]}
              >
                <h3>{data?.dob}</h3>
              </Col>
            </Row>
            <Row>
              <Col
                lg={5}
                md={5}
                sm={5}
                xs={12}
                className={classes["detail-title"]}
              >
                <h2>Age</h2>
              </Col>
              <Col
                lg={7}
                md={7}
                sm={7}
                xs={12}
                className={classes["detail-description"]}
              >
                <h3>{data?.age}</h3>
              </Col>
            </Row>
            <Row>
              <Col
                lg={5}
                md={5}
                sm={5}
                xs={12}
                className={classes["detail-title"]}
              >
                <h2>Gender</h2>
              </Col>
              <Col
                lg={7}
                md={7}
                sm={7}
                xs={12}
                className={classes["detail-description"]}
              >
                <h3>{data?.gender}</h3>
              </Col>
            </Row>
            <Row>
              <Col
                lg={5}
                md={5}
                sm={5}
                xs={12}
                className={classes["detail-title"]}
              >
                <h2>Refer To</h2>
              </Col>
              <Col
                lg={7}
                md={7}
                sm={7}
                xs={12}
                className={classes["detail-description"]}
              >
                <h3>{data?.referTo}</h3>
              </Col>
            </Row>
            <Row>
              <Col
                lg={5}
                md={5}
                sm={5}
                xs={12}
                className={classes["detail-title"]}
              >
                <h2>Selected Location</h2>
              </Col>
              <Col
                lg={7}
                md={7}
                sm={7}
                xs={12}
                className={classes["detail-description"]}
              >
                <h3>{data?.bName}</h3>
              </Col>
            </Row>
            {data?.diagnosis && (
              <Row>
                <Col
                  lg={5}
                  md={5}
                  sm={5}
                  xs={12}
                  className={classes["detail-title"]}
                >
                  <h2>Suspected Diagnosis</h2>
                </Col>
                <Col
                  lg={7}
                  md={7}
                  sm={7}
                  xs={12}
                  className={classes["detail-description"]}
                >
                  <h3>{data?.diagnosis}</h3>
                </Col>
              </Row>
            )}
            <Row>
              <Col
                lg={5}
                md={5}
                sm={5}
                xs={12}
                className={classes["detail-title"]}
              >
                <h2>Referred</h2>
              </Col>
              <Col
                lg={7}
                md={7}
                sm={7}
                xs={12}
                className={classes["detail-description"]}
              >
                <h3>{data?.referred}</h3>
              </Col>
            </Row>
            {data?.referralNotes && (
              <Row>
                <Col
                  lg={5}
                  md={5}
                  sm={5}
                  xs={12}
                  className={classes["detail-title"]}
                >
                  <h2>Referral Note</h2>
                </Col>
                <Col
                  lg={7}
                  md={7}
                  sm={7}
                  xs={12}
                  className={classes["detail-description"]}
                >
                  <h3>{data?.referralNotes}</h3>
                </Col>
              </Row>
            )}
          </div>
          <div className={classes["action-wrapper"]}>
            <Button
              label="Close"
              type="button"
              className={classes["cancel-button"]}
              onClick={setShow}
            />
          </div>
        </CommonCard>
      </Modal>
    </>
  );
};

export default DetailsModal;
