export const apiUrl = () => {
  let url;
  const environment = process.env.REACT_APP_ENVIRONMENT;
  switch (environment) {
    case "production":
      url = "https://nibdrnapi.qplnk.com"; //live
      break;
    case "development":
      url = "https://nibdrnapi.qplnk.com";
      break;
    case "staging":
      url = "http://192.168.1.11:2022";
      break;
    case "stagingLive":
      url = "http://23.227.206.234:5012";
      break;
    default:
      url = "https://localhost:7069";
  }
  return url;
};

export const imageUrl = (url) => `${apiUrl()}/api/images/${url}`;
/**
 *
 * @param {String} link
 * @returns {String}
 */
export const BaseURL = (link) => `${apiUrl()}/api/${link}`;

export const apiHeader = (token, isFormData) => {
  if (token && !isFormData) {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
  }
  if (token && isFormData) {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };
  }
  if (!token && !isFormData) {
    return {
      headers: {
        "Content-Type": "application/json",
      },
    };
  }

  if (!token && isFormData) {
    return {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
  }
};
