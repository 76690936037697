import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FaRegCircleUser } from "react-icons/fa6";
import { FiPhone } from "react-icons/fi";
import { GrLocation } from "react-icons/gr";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import { MdOutlineMail } from "react-icons/md";
import { TbBuildingHospital } from "react-icons/tb";
import { useSelector } from "react-redux";
import {
  njhsLogo,
  pendingCases,
  profile,
  defaultProfile,
  totalReferralCase,
} from "../../../assets/constant";
import CommonCard from "../../../components/CommonCard";
import Navbar from "../../../components/Navbar";
import classes from "./Dashboard.module.css";
import moment from "moment";
import { formatPhoneNumber } from "../../../helpers";
import Button from "../../../components/Button";
import { Get, Post } from "../../../AxiosCRUDFunctions";
import { BaseURL, apiHeader } from "../../../config";
import { toast } from "react-toastify";
import CarouselComponent from "../../../components/CarouselComponent";
import CarouselCard from "../../../components/CarouselCard";

const Dashboard = () => {
  const { token } = useSelector((state) => state?.authReducer);
  const date = new Date();
  const formattedDate = moment(date).format("MMM, YYYY");
  const [user, setUser] = useState(null);

  const loadProfile = async () => {
    const response = await Get(BaseURL("SignUp/LoadDashboardData"), token);
    if (response != undefined && response?.data?.headers.error === 200) {
      setUser(response?.data?.body);
    } else {
      toast.error(response?.data.headers?.message);
    }
  };

  useEffect(() => {
    loadProfile();
  }, []);

  return (
    <>
      <div>
        <Navbar title={"Referral Network"} />
        <Row
          className={[classes["cards-section"], classes["first-card"]].join(
            " "
          )}
        >
          <Col lg={9} md={12} sm={12} style={{}}>
            <CommonCard className={classes["commonCard-extra"]}>
              {user ? (
                <Row>
                  <Col lg={4} md={4} sm={4} className={classes["profile-img"]}>
                    <img
                      src={user?.image ? user?.image : defaultProfile}
                      alt="profile"
                    />
                  </Col>
                  <Col
                    lg={8}
                    md={8}
                    sm={8}
                    className={classes["details-wrapper"]}
                  >
                    <Row style={{ marginBottom: "20px" }}>
                      <Col className={classes[""]} lg={12} md={12} sm={12}>
                        <div className={classes["main-heading"]}>
                          <h1>
                            {user?.consultantName &&
                              (user?.consultantName?.includes("Dr")
                                ? user?.consultantName
                                : `Dr. ${user?.consultantName}`)}
                          </h1>
                          <p>{user?.qualification}</p>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        {user?.speciality && (
                          <Row>
                            <div className={classes["single-detail"]}>
                              <FaRegCircleUser
                                size={20}
                                style={{ marginTop: "2px" }}
                                className={classes["speciality-svg"]}
                              />
                              <p>{user?.speciality}</p>
                            </div>
                          </Row>
                        )}
                        {user?.email && (
                          <Row>
                            <div className={classes["single-detail"]}>
                              <MdOutlineMail />
                              <p>{user?.email}</p>
                            </div>
                          </Row>
                        )}
                        {user?.hospitalName && (
                          <Row>
                            <div className={classes["single-detail"]}>
                              <TbBuildingHospital />
                              <p>{user?.hospitalName}</p>
                            </div>
                          </Row>
                        )}
                      </Col>
                      <Col>
                        {user?.clinicName && (
                          <Row>
                            <div className={classes["single-detail"]}>
                              <HiOutlineOfficeBuilding />
                              <p>{user?.clinicName}</p>
                            </div>
                          </Row>
                        )}
                        {user?.phoneNo && (
                          <Row>
                            <div className={classes["single-detail"]}>
                              <FiPhone />
                              <p>{formatPhoneNumber(user?.phoneNo)}</p>
                            </div>
                          </Row>
                        )}
                        {user?.province && user?.city && (
                          <Row>
                            <div className={classes["single-detail"]}>
                              <GrLocation />
                              <p>
                                {user?.city}, {user?.province}
                              </p>
                            </div>
                          </Row>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              ) : (
                "Loading...."
              )}
            </CommonCard>
          </Col>
          <Col lg={3} md={12} sm={12} className={classes["caseCard-wrapper"]}>
            <CommonCard className={classes["caseCard"]}>
              <Row>
                <Col xl={4} sm={4} className={classes["cases-picture-card"]}>
                  <img src={totalReferralCase} alt="profile" />
                </Col>
                <Col xl={8} sm={8}>
                  <div className={classes["case-card-heading"]}>
                    <h1>My Referral Cases</h1>
                    <div className={classes["sub-headings"]}>
                      <p style={{ fontSize: "28px", fontWeight: "500" }}>
                        {user?.totalReferralCases}
                      </p>
                      <p>{formattedDate}</p>
                    </div>
                  </div>
                </Col>
              </Row>
            </CommonCard>
            <CommonCard className={classes["caseCard"]}>
              <Row>
                <Col sm={4} className={classes["cases-picture-card"]}>
                  <img src={pendingCases} alt="profile" />
                </Col>
                <Col sm={8}>
                  <div className={classes["case-card-heading"]}>
                    <h1>Pending Cases</h1>
                    <div className={classes["sub-headings"]}>
                      <p style={{ fontSize: "28px", fontWeight: "500" }}>
                        {user?.totalPendingCases}
                      </p>
                      <p>{formattedDate}</p>
                    </div>
                  </div>
                </Col>
              </Row>
            </CommonCard>
          </Col>
        </Row>
        <div className={classes["carousal-wrapper"]}>
          <Row>
            <h1 className={classes["Carousel-heading"]}>Our Expertise</h1>
          </Row>
          <CarouselComponent />
        </div>
        <div
          className={classes["cards-section"]}
          style={{ marginBottom: "20px" }}
        >
          <CommonCard className={classes["njhs-card-extraStyles"]}>
            <Row>
              <Col
                className={classes["njhs-logo-wrapper"]}
                xl={2}
                lg={3}
                md={5}
                sm={12}
              >
                <img src={njhsLogo} alt="logo" />
              </Col>
              <Col
                className={classes["njhs-description"]}
                xl={10}
                lg={9}
                md={7}
                sm={12}
              >
                <p>
                  National Journal of Health Sciences (NJHS) aims to provide a
                  platform that will construct substantial and reliable
                  involvement of the scientific community. NJHS will publish
                  scholarly articles as Open Access related to broad spectrum of
                  research disciplines.
                </p>
                <div className={classes["visit-wrapper"]}>
                  <p>To gather further information, please visit our website</p>
                  <Button
                    className={classes["btn"]}
                    onClick={() => window.open("https://njhsciences.com/")}
                  >
                    Visit Website
                  </Button>
                </div>
              </Col>
            </Row>
          </CommonCard>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
