import React from "react";
import CommonCard from "../../CommonCard";
import { Col, Row } from "react-bootstrap";
import classes from "./CarouselCard2.module.css";

const CarouselCard2 = () => {
  const item = {
    title: "Blood Cancers",
    description: [
      "Chronic Myeloid Leukaemia (CML)",
      "Acute Myeloid Leukaemia (AML)",
      "Acute Lymphoblastic Leukaemia (ALL)",
      "Chronic Lymphoblastic Leukaemia (CLL)",
      "Hodgkin’s Lymphoma (HL)",
      "Non-Hodgkin’s Lymphoma (NHL)",
      "Myelodysplastic Syndrome (MDS)",
      "Myeloproliferative Neoplasm (MPN)",
    ],
    image: "carousel2a.png",
  };
  return (
    <CommonCard className={classes["cardStyle"]}>
      <Row>
        <Col
          xl={5}
          lg={12}
          md={12}
          sm={12}
          className={[classes["imageWrapper"], classes["h-100"]].join(" ")}
        >
          <img src={require(`../../../assets/images/${item?.image}`)} alt="" />
        </Col>
        <Col
          xl={7}
          lg={12}
          md={12}
          sm={12}
          className={[classes["description-wrapper"], classes["h-100"]].join(
            " "
          )}
        >
          <div className={classes["main-heading"]}>
            <h1>{item?.title}</h1>
          </div>
          <div className={classes["details"]}>
            <ul>
              {item?.description?.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>
        </Col>
      </Row>
    </CommonCard>
  );
};

export default CarouselCard2;
