import React from "react";
import { useNavigate } from "react-router-dom";
import { lock } from "../../../../assets/constant";
import Button from "../../../../components/Button";
import classes from "./Step2.module.css";
import CommonCard from "../../../../components/CommonCard";
import FormWrapper from "../../../../components/FormWrapper";

const Step2 = ({ setStep }) => {
  const navigate = useNavigate();

  const handleSendEmail = async (e, data) => {
    e.preventDefault();
    navigate("/");
  };
  return (
    <CommonCard className={classes["extraCardStyles"]}>
      <span className={classes["brand-logo"]}>
        <img src={lock && lock} alt="logo" />
      </span>
      <div className={classes["main-heading"]}>
        <h1>Reset Request Sent</h1>
        <p>
          You will get an email with instructions on resetting your password. If
          it doesn’t arrive, be sure to check your spam folder.
        </p>
      </div>
      <FormWrapper handlerFunction={handleSendEmail}>
        <Button
          label="Back to Sign In"
          type="submit"
          className={classes["signIn-button"]}
        />
      </FormWrapper>
    </CommonCard>
  );
};

export default Step2;
