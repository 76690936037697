import React, { useState } from "react";
import { toast } from "react-toastify";
import { Post } from "../../../../AxiosCRUDFunctions";
import { lock } from "../../../../assets/constant";
import Button from "../../../../components/Button";
import { Input } from "../../../../components/Input";
import { BaseURL, apiHeader } from "../../../../config";
import classes from "./UpdatePassword.module.css";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import CommonCard from "../../../../components/CommonCard";
import FormWrapper from "../../../../components/FormWrapper";
import { Row } from "react-bootstrap";

const UpdatePassword = () => {
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const key = params.get("id");
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleUpdatePassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (password && confirmPassword && password === confirmPassword) {
      const data = { key, password };
      const response = await Post(
        BaseURL("SignUp/UpdatePassword"),
        data,
        apiHeader()
      );
      if (response != undefined) {
        toast.success("Password Updated Successfully");
        navigate("/");
      } else {
        toast.error(response?.data?.headers?.message);
      }
    } else {
      toast.error("New Password and Confirm Password must be same");
    }
    setLoading(false);
  };
  return (
    <div className={classes["main-wrapper"]}>
      <CommonCard className={classes["extraCardStyles"]}>
        <span className={classes["brand-logo"]}>
          <img src={lock && lock} alt="logo" />
        </span>
        <div className={classes["main-heading"]}>
          <h1>Change Your Password</h1>
          <p>Update your account password for enhanced security. </p>
        </div>
        <FormWrapper handlerFunction={handleUpdatePassword}>
          <Row>
            <Input
              label={"New Password"}
              value={password}
              setter={(val) => setPassword(val)}
              placeholder={"Enter New Password"}
              type={"password"}
            />
          </Row>
          <Row>
            <Input
              label={"Confirm Password"}
              value={confirmPassword}
              setter={(val) => setConfirmPassword(val)}
              placeholder={"Enter Confirm Password"}
              type={"password"}
            />
          </Row>
          <Button
            label="Update Password"
            type="submit"
            className={classes["signIn-button"]}
          />
        </FormWrapper>
      </CommonCard>
    </div>
  );
};

export default UpdatePassword;
