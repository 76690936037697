import React, { useEffect, useState } from "react";
import Navbar from "../../../components/Navbar";
import FormWrapper from "../../../components/FormWrapper";
import { Col, Row } from "react-bootstrap";
import { Input } from "../../../components/Input";
import classes from "./Account.module.css";
import Dropdown from "../../../components/Dropdown";
import { BaseURL, apiHeader } from "../../../config";
import { Get, Post } from "../../../AxiosCRUDFunctions";
import validator from "validator";
import CommonCard from "../../../components/CommonCard";
import Button from "../../../components/Button";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/Loader";
import UpdatePasswordModal from "../../../modals/UpdatePasswordModal";
import SuccessModal from "../../../modals/SuccessModal";
import UploadFile from "../../../components/UploadFile";
import { profile, defaultProfile } from "../../../assets/constant";
import { LuUpload } from "react-icons/lu";
import { logout } from "../../../store/authSlice";

const Account = () => {
  const { token } = useSelector((state) => state?.authReducer);
  const dispatch = useDispatch();
  const [showUpdatePassword, setShowUpdatePassword] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [speciality, setSpeciality] = useState(null);
  const [province, setProvince] = useState(null);
  const [cities, setCities] = useState(null);
  const [image, setImage] = useState(null);
  const [updatePasswordData, setUpdatePasswordData] = useState({
    currentPassword: "",
    password: "",
    confirmPassword: "",
  });

  const cancelHandler = () => {
    setShowUpdatePassword(false);
    setUpdatePasswordData({
      currentPassword: "",
      password: "",
      confirmPassword: "",
    });
  };

  const changePasswordHandler = async () => {
    if (
      updatePasswordData?.currentPassword &&
      updatePasswordData?.password &&
      updatePasswordData?.confirmPassword
    ) {
      if (
        updatePasswordData?.password === updatePasswordData?.confirmPassword
      ) {
        const body = {
          currentPassword: updatePasswordData?.currentPassword,
          password: updatePasswordData?.password,
        };
        const response = await Post(
          BaseURL("SignUp/ChangePassword"),
          body,
          apiHeader(token)
        );
        if (response != undefined && response?.data?.headers.error === 200) {
          toast.success("Password Updated Successfully");
          dispatch(logout());
          cancelHandler();
        } else {
          toast.error(response?.data.headers?.message);
        }
      } else {
        toast.error("Password and Confirm Password must be same");
      }
    } else {
      toast.error("Required fields must be filled");
    }
  };

  const splitPhoneNumber = (number) => {
    if (number?.includes("+92")) {
      return number.replace("+92", "");
    } else {
      return number;
    }
  };

  const loadControls = async () => {
    setLoading(true);
    const response = await Get(BaseURL("SignUp/LoadControls"), apiHeader());
    setSpeciality(response?.data?.body?.dbSpecialityList);
    setProvince(response?.data?.body?.dbProvinceList);
    setCities(response?.data?.body?.dbCityList);

    const response1 = await Post(
      BaseURL("ReferralPatients/GetAccountSettings"),
      {},
      apiHeader(token)
    );
    const details = response1?.data?.body;
    const speciality = response1?.data?.body?.specialityDropDown?.map(
      (item) => ({
        label: item?.name,
        value: item?.code,
      })
    );
    setData({
      consultantName: details?.consultantName,
      speciality: speciality,
      academicQualification: details?.academicQualification,
      position: details?.position,
      emailAddress: details?.emailAddress,
      phoneNo: splitPhoneNumber(details?.phoneNo),
      province: { value: details?.provinceId, label: details?.provinceName },
      city: { value: details?.cityId, label: details?.cityName },
      address: details?.address,
      hospitalInstitutionName: details?.hospitalInstitutionName,
      hospitalContactNo: splitPhoneNumber(details?.hospitalPhoneNo),
      hospitalEmailAddress: details?.hospitalEmail,
      hospitalAddress: details?.hospitalAddress,
      privateClinicName: details?.privateClinicName,
      privateClinicContactNo: splitPhoneNumber(details?.privateClinicPhoneNo),
      privateClinicEmailAddress: details?.privateClinicEmail,
      privateClinicAddress: details?.privateClinicName,
      image: details?.image,
    });
    setLoading(false);
  };

  const validateEmail = (email) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    debugger;
    if (
      data?.consultantName &&
      data.speciality &&
      data?.academicQualification &&
      data?.emailAddress &&
      data.phoneNo &&
      data?.province &&
      data?.city
    ) {
      if (validator?.isMobilePhone(`+92${data?.phoneNo}`)) {
        toast.error("Must be a Valid phone number");
      } else if (!validateEmail(data?.emailAddress)) {
        toast.error("Must be a Valid Email Adress");
      } else if (data?.hospitalInstitutionName) {
        if (
          data?.hospitalEmailAddress &&
          data?.hospitalAddress &&
          data?.hospitalContactNo
        ) {
          if (validator?.isMobilePhone(`+92${data?.hospitalContactNo}`)) {
            toast.error("Must be a Valid phone number");
          } else if (!validateEmail(data?.hospitalEmailAddress)) {
            toast.error("Must be a Valid Email Adress");
          } else if (data?.privateClinicName) {
            if (
              data?.privateClinicName &&
              data?.privateClinicContactNo &&
              data?.privateClinicEmailAddress &&
              data?.privateClinicAddress
            ) {
              if (
                validator?.isMobilePhone(`+92${data?.privateClinicContactNo}`)
              ) {
                toast.error("Must be a Valid phone number");
              } else if (!validateEmail(data?.privateClinicEmailAddress)) {
                toast.error("Must be a Valid Email Adress");
              } else {
                handleSaveChanges();
              }
            } else {
              toast.error("Required field must be filled");
            }
          } else {
            handleSaveChanges();
          }
        } else {
          toast.error("Required field must be filled");
        }
      } else if (data?.privateClinicName) {
        if (
          data?.privateClinicName &&
          data?.privateClinicContactNo &&
          data?.privateClinicEmailAddress &&
          data?.privateClinicAddress
        ) {
          if (!validateEmail(data?.privateClinicEmailAddress)) {
            toast.error("Must be a Valid Email Adress");
          } else {
            handleSaveChanges();
          }
        } else {
          toast.error("Required field must be filled");
        }
      } else {
        handleSaveChanges();
      }
    } else {
      toast.error("Required field must be filled");
    }
  };

  const handleSaveChanges = async () => {
    setLoading(true);
    const { confirmPassword, speciality, city, province, ...rest } = data;

    const specialityCode = speciality?.map((item) => item.value);
    const body = {
      ...rest,
      phoneNo: `+92${data?.phoneNo}`,
      hospitalContactNo: `+92${data?.hospitalContactNo}`,
      privateClinicContactNo: `+92${data?.privateClinicContactNo}`,
      specialityCode,
      provinceId: data?.province?.value,
      cityId: data?.city?.value,
    };
    const response = await Post(
      BaseURL("ReferralPatients/UpdateAccountSettings"),
      body,
      apiHeader(token)
    );
    if (response != undefined && response?.data?.headers.error === 200) {
      toast.success("Data Updated Successfully");
      setShowSuccess(true);
    } else {
      toast.error(response?.data.headers?.message);
    }
    setLoading(false);
  };

  const loadCities = async (id) => {
    const response = await Get(
      BaseURL(`SignUp/GetCitiesByID?provinceId=${id}`)
    );
    setCities(response?.data?.body?.dbCityList);
  };

  const uploadImageHandler = async (image) => {
    if (image) {
      setLoading(true);
      const response = await Post(
        BaseURL("ReferralPatients/UploadImage"),
        { image },
        apiHeader(token)
      );
      if (response != undefined && response?.data?.headers.error === 200) {
        setImage(response?.data?.body);
        toast.success("Image Uploaded Successfully");
        setShowImageModal(false);
        setShowSuccess(true);
      } else {
        toast.error(response?.data.headers?.message);
      }
      setLoading(false);
    } else {
      toast.error("Please Select image to Upload");
    }
  };

  useEffect(() => {
    loadControls();
  }, []);

  useEffect(() => {
    if (data?.province) {
      loadCities(data?.province?.value);
    }
  }, [data?.province]);

  return (
    <div style={{ marginBottom: "50px" }}>
      <Navbar title={"Account Settings"} />
      {loading ? (
        <Loader />
      ) : (
        <>
          <CommonCard className={classes["extraCardStyles"]}>
            <FormWrapper handlerFunction={handleSubmit}>
              <Row style={{ justifyContent: "space-between" }}>
                <Col lg={2} md={12} sm={12} className={classes["profile-img"]}>
                  <img
                    src={
                      image ? image : data?.image ? data?.image : defaultProfile
                    }
                    alt="profile"
                  />
                </Col>
                <Col
                  lg={6}
                  md={12}
                  sm={12}
                  className={classes["profile-img-heading"]}
                >
                  <div className={classes["main-heading"]}>
                    <h1>
                      {data?.consultantName?.includes("Dr")
                        ? data?.consultantName
                        : `Dr. ${data?.consultantName}`}
                    </h1>
                    <p>PNG or JPG no bigger than 1000px wide and tall.</p>
                  </div>
                </Col>
                <Col lg={4} md={12} sm={12}>
                  <div
                    className={classes["upload-btn"]}
                    onClick={() => setShowImageModal(true)}
                  >
                    <LuUpload
                      size={15}
                      color="var(--slate-gray)"
                      style={{ marginTop: "-2px" }}
                    />
                    <p>Upload Image</p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xl={6} lg={6} md={12} sm={12}>
                  <div className={classes["dr-wrapper"]}>
                    <Input
                      label={"Consultant Name "}
                      value={data?.consultantName}
                      setter={(val) =>
                        setData((prev) => ({
                          ...prev,
                          consultantName: val,
                        }))
                      }
                      placeholder={"Enter Consultant Name"}
                      required={true}
                      labelStyle={{ fontWeight: 600 }}
                      salutation={"Dr."}
                    />
                  </div>
                </Col>
                <Col xl={6} lg={6} md={12} sm={12}>
                  <Dropdown
                    isMulti={true}
                    label={"Speciality "}
                    value={data?.speciality}
                    setter={(val) =>
                      setData((prev) => ({
                        ...prev,
                        speciality: val,
                      }))
                    }
                    placeholder={"Speciality"}
                    required={true}
                    labelStyle={{ fontWeight: 600 }}
                    options={speciality?.map((data) => {
                      return {
                        label: data?.name,
                        value: data?.code,
                      };
                    })}
                    isSearchable={true}
                  />
                </Col>
              </Row>
              <Row>
                <Input
                  label={"Academic Qualification "}
                  value={data?.academicQualification}
                  setter={(val) =>
                    setData((prev) => ({
                      ...prev,
                      academicQualification: val,
                    }))
                  }
                  placeholder={"Enter Academic Qualification"}
                  required={true}
                  labelStyle={{ fontWeight: 600 }}
                  message={"Example. MBBS, FCPS, MCPS etc"}
                />
              </Row>
              <Row>
                <Input
                  label={"Position "}
                  value={data?.position}
                  setter={(val) =>
                    setData((prev) => ({
                      ...prev,
                      position: val,
                    }))
                  }
                  placeholder={"Enter Position"}
                  labelStyle={{ fontWeight: 600 }}
                  message={
                    "Example. Assistant Professor, Assosiate Professor, Professor"
                  }
                />
              </Row>
              <Row>
                <Col xl={6} lg={6} md={12} sm={12}>
                  <Input
                    type={"email"}
                    label={"Email Address "}
                    value={data?.emailAddress}
                    setter={(val) =>
                      setData((prev) => ({
                        ...prev,
                        emailAddress: val,
                      }))
                    }
                    disabled={true}
                    placeholder={"Enter Email Address"}
                    required={true}
                    labelStyle={{ fontWeight: 600 }}
                  />
                </Col>
                <Col xl={6} lg={6} md={12} sm={12}>
                  <Input
                    type={"number"}
                    label={`Phone Number `}
                    placeholder={`Enter Phone Number`}
                    value={data?.phoneNo}
                    setter={(val) => {
                      if (val.length === 1 && val === "0") {
                        return;
                      } else {
                        setData((prev) => ({
                          ...prev,
                          phoneNo: val,
                        }));
                      }
                    }}
                    disabled={true}
                    labelStyle={{ fontWeight: 600 }}
                    required={true}
                    salutation={"+92"}
                    salutationStyles={{ width: "15%" }}
                  />
                </Col>
              </Row>
              <Row>
                <Col xl={6} lg={6} md={12} sm={12}>
                  <Dropdown
                    label={"Province "}
                    value={data?.province}
                    setter={(val) =>
                      setData((prev) => ({
                        ...prev,
                        province: val,
                      }))
                    }
                    placeholder={"Search Province"}
                    required={true}
                    labelStyle={{ fontWeight: 600 }}
                    options={province?.map((data) => {
                      return {
                        label: data?.stateName,
                        value: data?.stateId,
                      };
                    })}
                    isSearchable={true}
                  />
                </Col>
                <Col xl={6} lg={6} md={12} sm={12}>
                  <Dropdown
                    label={"City "}
                    value={data?.city}
                    setter={(val) =>
                      setData((prev) => ({
                        ...prev,
                        city: val,
                      }))
                    }
                    placeholder={"Search City"}
                    required={true}
                    labelStyle={{ fontWeight: 600 }}
                    options={cities?.map((data) => {
                      return {
                        label: data?.cityName,
                        value: data?.cityId,
                      };
                    })}
                    isSearchable={true}
                  />
                </Col>
              </Row>
              <Row>
                <Input
                  label={"Address "}
                  value={data?.address}
                  setter={(val) =>
                    setData((prev) => ({
                      ...prev,
                      address: val,
                    }))
                  }
                  placeholder={"Enter Address"}
                  labelStyle={{ fontWeight: 600 }}
                />
              </Row>
              <Row>
                <Input
                  label={"Hospital/Institution Name "}
                  value={data?.hospitalInstitutionName}
                  setter={(val) =>
                    setData((prev) => ({
                      ...prev,
                      hospitalInstitutionName: val,
                    }))
                  }
                  placeholder={"Enter Hospital Name"}
                  labelStyle={{ fontWeight: 600 }}
                  required={data?.hospitalInstitutionName}
                />
              </Row>
              <Row>
                <Col xl={6} lg={6} md={12} sm={12}>
                  <Input
                    type={"number"}
                    label={`Hospital Phone Number `}
                    placeholder={`Enter Hospital Phone Number`}
                    value={data?.hospitalContactNo}
                    setter={(val) => {
                      if (val.length === 1 && val === "0") {
                        return;
                      } else {
                        setData((prevData) => {
                          return { ...prevData, hospitalContactNo: val };
                        });
                      }
                    }}
                    labelStyle={{ fontWeight: 600 }}
                    required={data?.hospitalInstitutionName}
                    salutation={"+92"}
                    salutationStyles={{ width: "15%" }}
                  />
                </Col>
                <Col xl={6} lg={6} md={12} sm={12}>
                  <Input
                    type={"email"}
                    label={"Hospital Email Address "}
                    value={data?.hospitalEmailAddress}
                    setter={(val) =>
                      setData((prev) => ({
                        ...prev,
                        hospitalEmailAddress: val,
                      }))
                    }
                    placeholder={"Enter Hospital Email Address"}
                    labelStyle={{ fontWeight: 600 }}
                    required={data?.hospitalInstitutionName}
                  />
                </Col>
              </Row>

              <Row>
                <Input
                  label={"Hospital Address "}
                  value={data?.hospitalAddress}
                  setter={(val) =>
                    setData((prev) => ({
                      ...prev,
                      hospitalAddress: val,
                    }))
                  }
                  placeholder={"Enter Hospital Address"}
                  labelStyle={{ fontWeight: 600 }}
                  required={data?.hospitalInstitutionName}
                />
              </Row>
              <Row>
                <Input
                  label={"Private Clinic Name "}
                  value={data?.privateClinicName}
                  setter={(val) =>
                    setData((prev) => ({
                      ...prev,
                      privateClinicName: val,
                    }))
                  }
                  placeholder={"Enter Clinic Name"}
                  labelStyle={{ fontWeight: 600 }}
                  required={data?.privateClinicName}
                />
              </Row>
              <Row>
                <Col xl={6} lg={6} md={12} sm={12}>
                  <Input
                    type={"number"}
                    label={`Phone Clinic Number `}
                    placeholder={`Enter Clinic Phone Number`}
                    value={data?.privateClinicContactNo}
                    setter={(val) => {
                      if (val.length === 1 && val === "0") {
                        return;
                      } else {
                        setData((prevData) => {
                          return { ...prevData, privateClinicContactNo: val };
                        });
                      }
                    }}
                    labelStyle={{ fontWeight: 600 }}
                    required={data?.privateClinicName}
                    salutation={"+92"}
                    salutationStyles={{ width: "15%" }}
                  />
                </Col>
                <Col xl={6} lg={6} md={12} sm={12}>
                  <Input
                    type={"email"}
                    label={"Clinic Email Address "}
                    value={data?.privateClinicEmailAddress}
                    setter={(val) =>
                      setData((prev) => ({
                        ...prev,
                        privateClinicEmailAddress: val,
                      }))
                    }
                    placeholder={"Enter Clinic Email Address"}
                    labelStyle={{ fontWeight: 600 }}
                    required={data?.privateClinicName}
                  />
                </Col>
              </Row>
              <Row>
                <Input
                  label={"Clinic Address "}
                  value={data?.privateClinicAddress}
                  setter={(val) =>
                    setData((prev) => ({
                      ...prev,
                      privateClinicAddress: val,
                    }))
                  }
                  placeholder={"Enter Clinic Address"}
                  labelStyle={{ fontWeight: 600 }}
                  required={data?.privateClinicName}
                />
              </Row>
              <Row className={classes["btn-wrapper"]}>
                <Button type="submit" className={classes["btn"]}>
                  Save Changes
                </Button>
              </Row>
            </FormWrapper>
          </CommonCard>
          <CommonCard className={classes["extraCardStyles2"]}>
            <div className={classes["heading-wrapper"]}>
              <h1 className={classes["name-h1"]}>Change Password</h1>
            </div>
            <div className={classes["details-wrapper"]}>
              <Row>
                <p>
                  Please enter a new password to update your account security.
                </p>
              </Row>
              <Row>
                <Button
                  className={classes["extra-btn"]}
                  onClick={() => setShowUpdatePassword(true)}
                >
                  Change Password
                </Button>
              </Row>
            </div>
          </CommonCard>
          <UpdatePasswordModal
            show={showUpdatePassword}
            setShow={cancelHandler}
            handler={changePasswordHandler}
            data={updatePasswordData}
            setData={setUpdatePasswordData}
          />
          <SuccessModal
            show={showSuccess}
            setShow={() => setShowSuccess(false)}
            data={"You have successfully save your changes"}
          />
          <UploadFile
            show={showImageModal}
            setShow={() => setShowImageModal(false)}
            handler={uploadImageHandler}
          />
        </>
      )}
    </div>
  );
};

export default Account;
