import React from "react";
import { Modal, Row } from "react-bootstrap";
import classes from "./UpdatePasswordModal.module.css";
import Button from "../../components/Button";
import CommonCard from "../../components/CommonCard";
import FormWrapper from "../../components/FormWrapper";
import { Input } from "../../components/Input";

const UpdatePasswordModal = ({ show, setShow, handler, data, setData }) => {
  return (
    <>
      <style>{`
      .modal-md {
        --bs-modal-width: 500px;
      }
      .modal-content{
        border-radius: 16px;
      }
    `}</style>
      <Modal
        show={show}
        onHide={setShow}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <CommonCard className={classes["extraCardStyles"]}>
          <div className={classes["heading-wrapper"]}>
            <h1>Update Your Password</h1>
            <p>Update your account password for enhanced security.</p>
          </div>
          <FormWrapper>
            <Row>
              <Input
                label={"Current Password"}
                value={data?.currentPassword}
                setter={(val) =>
                  setData((prev) => ({ ...prev, currentPassword: val }))
                }
                placeholder={"Enter Current Password"}
                type={"password"}
                required={true}
                labelStyle={{ fontWeight: 600 }}
              />
            </Row>
            <Row>
              <Input
                label={"New Password"}
                value={data?.password}
                setter={(val) =>
                  setData((prev) => ({ ...prev, password: val }))
                }
                placeholder={"Enter New Password"}
                type={"password"}
                labelStyle={{ fontWeight: 600 }}
                required={true}
              />
            </Row>
            <Row>
              <Input
                label={"Confirm Password"}
                value={data?.confirmPassword}
                setter={(val) =>
                  setData((prev) => ({ ...prev, confirmPassword: val }))
                }
                placeholder={"Enter Confirm Password"}
                type={"password"}
                required={true}
                labelStyle={{ fontWeight: 600 }}
              />
            </Row>
          </FormWrapper>
          <div className={classes["action-wrapper"]}>
            <Button
              label="Cancel"
              type="button"
              className={classes["cancel-button"]}
              onClick={setShow}
            />
            <Button
              label="Update Password"
              onClick={handler}
              className={classes["signIn-btn"]}
            />
          </div>
        </CommonCard>
      </Modal>
    </>
  );
};

export default UpdatePasswordModal;
