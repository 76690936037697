import React, { useEffect, useState } from "react";
import { Get, Post } from "../../../AxiosCRUDFunctions";
import Navbar from "../../../components/Navbar";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import PatientDataTable from "../../../components/DataTable/PatientDataTable";
import Loader from "../../../components/Loader";
import { BaseURL, apiHeader } from "../../../config";
import useDebounce from "../../../customHooks/useDebounce";
import DetailsModal from "../../../modals/DetailsModal";
import MainPopUp from "../../../components/MainPopUp";
import { base64ToPdf, base64ToBlob } from "../../../helpers/pdfUtils";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import Button from "../../../components/Button";
import classes from "./Patient.module.css";

const Patient = () => {
  const [show, setShow] = useState(false);
  const [data, setData] = useState(null);
  const [base64Data, setBase64Data] = useState(null);
  const [url, setUrl] = useState(null);
  const [base64Modal, setBase64Modal] = useState(false);
  const [allPatientRefferalData, setAllPatientRTefferalData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState({ label: 10, value: 10 });
  const { token } = useSelector((state) => state?.authReducer);
  const debouncedSearch = useDebounce(search, 600);
  const getAllRefferalPatientData = async (
    pageNo = 1,
    limit = 10,
    search = "",
    sortField = "",
    sortDirection = "desc"
  ) => {
    setLoading(true);
    const response = await Post(
      BaseURL("ReferralPatients/GetAllReferralPatientsListing"),
      {
        pageNumber: pageNo,
        pageSize: limit,
        search,
        sortField: "",
        sortDirection: "",
      },
      apiHeader(token)
    );
    setAllPatientRTefferalData(response?.data?.body?.data || []);
    setTotalCount(response?.data?.body?.pagination?.totalRowsCount || 0);
    setLoading(false);
  };

  useEffect(() => {
    if (limit?.value > totalCount) {
      setPage(1);
    }
    getAllRefferalPatientData(page, limit?.value, search);
  }, [debouncedSearch, limit, page]);

  const getById = async (id) => {
    setLoading(true);
    const response = await Get(
      BaseURL(`ReferralPatients/GetPatientDetailByReferralPatientID?Id=${id}`),
      token
    );
    if (response?.data?.body !== "") {
      setData(response?.data?.body);
      setLoading(false);
      setShow(true);
    } else {
      toast.error("Something Went Wrong");
    }
    setLoading(false);
  };
  const pdfHandler = async (id) => {
    setLoading(true);
    const response = await Get(
      BaseURL(`ReferralPatients/PrintReferralAssesmentReport?Id=${id}`),
      token
    );
    if (response?.data?.body !== "") {
      setBase64Data(response?.data?.body);

      const base64Pdf = `data:application/pdf;base64,${response?.data?.body}`;
      const blob = base64ToBlob(base64Pdf, "application/pdf");
      const url = URL.createObjectURL(blob);
      setUrl(url);
      setBase64Modal(true);
    } else {
      toast.error("Something Went Wrong");
    }
    setLoading(false);
  };

  const handleDownload = async () => {
    await base64ToPdf(base64Data);
  };

  return (
    <div>
      <Navbar title={"My Patient(s)"} />
      {loading ? (
        <Loader />
      ) : (
        <PatientDataTable
          tableActions={{
            data: allPatientRefferalData,
            page,
            setPage,
            search,
            setSearch,
            limit,
            setLimit,
            totalCount,
            getAllRefferalPatientData,
          }}
          handler={getById}
          pdfHandler={pdfHandler}
        />
      )}
      <DetailsModal show={show} setShow={() => setShow(false)} data={data} />

      <MainPopUp
        show={base64Modal}
        setShow={() => setBase64Modal(false)}
        download={true}
        downloadPdf={handleDownload}
      >
        <div
          style={{
            height: "100%",
            width: "500px",
          }}
        >
          {url !== null && (
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
              <Viewer fileUrl={url} />
            </Worker>
          )}
        </div>
      </MainPopUp>
    </div>
  );
};

export default Patient;
