import React from "react";
import Navbar from "../../../components/Navbar";

const ReferralCriteria = () => {
  return (
    <div>
      <Navbar title={"Referral Criteria"} />
    </div>
  );
};

export default ReferralCriteria;
