import React from "react";
import Navbar from "../../../components/Navbar";
import classes from "./Location.module.css";
import { Col, Row } from "react-bootstrap";
import LocationCard from "../../../components/LocationCard";
import data from "./data.json";

const Location = () => {
  const locationHandler = (url) => {
    window.open(`https://www.google.com/maps/place/${url}`);
  };
  return (
    <div>
      <Navbar title={"Location List"} />
      <Row className={classes["main-div"]}>
        {data?.locations?.map((item, index) => (
          <Col
            key={index}
            xl={3}
            lg={4}
            md={4}
            sm={6}
            xs={12}
            style={{ marginTop: "20px" }}
          >
            <LocationCard data={item} handler={locationHandler} />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Location;
