import React from "react";
import classes from "./Step1.module.css";
import { Input } from "../../../../components/Input";
import { Col, Row } from "react-bootstrap";
import Dropdown from "../../../../components/Dropdown";
import FormWrapper from "../../../../components/FormWrapper";

const Step1 = ({ data, setData, speciality }) => {
  return (
    <div>
      <FormWrapper>
        <Row>
          <Col xl={6} md={6} sm={12}>
            <div className={classes["dr-wrapper"]}>
              <Input
                label={"Consultant Name "}
                value={data.consultantName}
                setter={(val) =>
                  setData((prev) => ({
                    ...prev,
                    consultantName: val,
                  }))
                }
                placeholder={"Enter Consultant Name"}
                required={true}
                labelStyle={{ fontWeight: 600 }}
                salutation={"Dr."}
              />
            </div>
          </Col>
          <Col xl={6} md={6} sm={12}>
            <Dropdown
              isMulti={true}
              label={"Speciality "}
              value={data?.speciality}
              setter={(val) =>
                setData((prev) => ({
                  ...prev,
                  speciality: val,
                }))
              }
              placeholder={"Speciality"}
              required={true}
              labelStyle={{ fontWeight: 600 }}
              options={speciality?.map((data) => {
                return {
                  label: data?.name,
                  value: data?.code,
                };
              })}
              isSearchable={true}
            />
          </Col>
        </Row>
        <Row>
          <Input
            label={"Academic Qualification "}
            value={data.academicQualification}
            setter={(val) =>
              setData((prev) => ({
                ...prev,
                academicQualification: val,
              }))
            }
            placeholder={"Enter Academic Qualification"}
            required={true}
            labelStyle={{ fontWeight: 600 }}
            message={"Example. MBBS, FCPS, MCPS etc"}
          />
        </Row>
        <Row>
          <Input
            label={"Position "}
            value={data.position}
            setter={(val) =>
              setData((prev) => ({
                ...prev,
                position: val,
              }))
            }
            placeholder={"Enter Position"}
            labelStyle={{ fontWeight: 600 }}
            message={
              "Example. Assistant Professor, Assosiate Professor, Professor"
            }
          />
        </Row>
      </FormWrapper>
    </div>
  );
};

export default Step1;
