import React from "react";
import CommonCard from "../../CommonCard";
import { Col, Row } from "react-bootstrap";
import classes from "./CarouselCard3.module.css";

const CarouselCard3 = () => {
  const item = {
    title: "Benign Inherited Disorders",
    description: [
      "B-Thalassemia",
      "Immunodeficiency Syndromes",
      "Haemophilia",
      "Von Willebrand Disease",
      "Platelet Disorders",
    ],
    image: "carousel3.png",
  };
  return (
    <CommonCard className={classes["cardStyle"]}>
      <Row>
        <Col
          xl={5}
          lg={12}
          md={12}
          sm={12}
          className={[classes["imageWrapper"], classes["h-100"]].join(" ")}
        >
          <img src={require(`../../../assets/images/${item?.image}`)} alt="" />
        </Col>
        <Col
          xl={7}
          lg={12}
          md={12}
          sm={12}
          className={[classes["description-wrapper"], classes["h-100"]].join(
            " "
          )}
        >
          <div className={classes["main-heading"]}>
            <h1>{item?.title}</h1>
          </div>
          <div className={classes["details"]}>
            <ul>
              {item?.description?.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>
        </Col>
      </Row>
    </CommonCard>
  );
};

export default CarouselCard3;
