import React, { useState } from "react";
import classes from "./UploadFile.module.css";
import "./UploadFile.css";
import { BiCloudUpload } from "react-icons/bi";
import { Modal } from "react-bootstrap";
import Button from "../Button";
import { MdOutlineCancel } from "react-icons/md";
import { toast } from "react-toastify";

function UploadFile({ show, setShow, handler }) {
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      if (selectedFile.size > 5 * 1024 * 1024) {
        toast.warning("File size must be less than 5MB");
        return;
      }

      const img = new Image();
      img.src = URL.createObjectURL(selectedFile);
      img.onload = function () {
        if (img.width > 1024 || img.height > 1024) {
          toast.warning("Image dimensions should not be more than 1024x1024");
          return;
        }

        const reader = new FileReader();
        reader.readAsDataURL(selectedFile);
        reader.onload = function () {
          const base64String = reader.result;
          setFile(base64String);
          setPreview(base64String);
          uploadFile(selectedFile);
        };
        reader.onerror = function (error) {
          console.error("Error: ", error);
        };
      };
    }
  };

  const uploadFile = (uploadedFile) => {
    const totalSize = uploadedFile?.size;
    let uploadedBytes = 0;
    const progressInterval = setInterval(() => {
      uploadedBytes += Math.random() * 1000;
      const progress = Math.min((uploadedBytes / totalSize) * 100, 100);
      setUploadProgress(progress);
      if (progress === 100) {
        clearInterval(progressInterval);
      }
    }, 200);
  };

  const handleUploadClick = () => {
    document.getElementById("file-input").click();
  };

  return (
    <Modal
      show={show}
      onHide={setShow}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="container">
        <div className="heading-wrapper">
          <h1>Upload</h1>
          <p>Upload your Profile picture here</p>
        </div>
        <div className="upload-area" onClick={handleUploadClick}>
          <BiCloudUpload
            size={100}
            color={"var(--main-color)"}
            style={{ strokeWidth: "0" }}
          />
          <input
            id="file-input"
            type="file"
            onChange={handleFileChange}
            accept="image/png, image/gif, image/jpeg"
            style={{ display: "none" }}
          />
          <p>Click here to upload</p>
          <p style={{ color: "var(--blue-gray)" }}>
            Max 5 MB files are allowed
          </p>
          <p style={{ color: "var(--blue-gray)" }}>
            Image size should not be more than 1024x1024
          </p>
        </div>
        {file && (
          <div className="file-item">
            <div className="file-icon">
              <img src={preview} alt="" />
            </div>
            <div className="progress-bar">
              <div
                className="progress"
                style={{ width: `${uploadProgress}%` }}
              ></div>
            </div>
            <div className={classes["cancel-icon"]}>
              <MdOutlineCancel
                size={25}
                color={"var(--error-color)"}
                onClick={() => {
                  setFile(null);
                  setPreview(null);
                  setUploadProgress(0);
                }}
              />
              <p>{Math.round(uploadProgress)}%</p>
            </div>
          </div>
        )}
        <div className={classes["action-wrapper"]}>
          <Button
            label="Cancel"
            type="button"
            className={classes["cancel-button"]}
            onClick={() => {
              setFile(null);
              setPreview(null);
              setUploadProgress(0);
              setShow();
            }}
          />

          <Button
            label="Confirm"
            onClick={() => {
              handler(file);
            }}
            className={classes["signIn-button"]}
            disabled={uploadProgress !== 100}
          >
            Confirm
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default UploadFile;
