import React from "react";
import classes from "./Button.module.css";
const Button = ({
  className,
  children,
  label,
  onClick,
  disabled,
  type = "button",
  title,
  arialLabel,
  ...props
}) => {
  return (
    <button
      type={type}
      className={[classes["button"], className].join(" ")}
      onClick={onClick}
      disabled={disabled}
      title={title}
      aria-label={arialLabel}
      {...props}
    >
      {label || children}
    </button>
  );
};

export default Button;
