import React from "react";
import CommonCard from "../../CommonCard";
import { Col, Row } from "react-bootstrap";
import classes from "./CarouselCard5.module.css";

const CarouselCard5 = () => {
  const item = {
    title: "Cancers",
    subTitle: "Solid Tumours",
    description: [
      "Brain",
      "Head and Neck",
      "Breast",
      "Lung",
      "Colorectal",
      "Gastric",
      "Ovarian",
      "Pancreatic-Biliary",
      "Uterus",
      "Cervical",
      "Renal",
      "Bladder",
      "Prostate",
      "Bone Cancers",
      "Carcinomas",
    ],
    image: "carousel5.png",
  };
  return (
    <CommonCard className={classes["cardStyle"]}>
      <Row>
        <Col
          xl={5}
          lg={12}
          md={12}
          sm={12}
          className={[classes["imageWrapper"], classes["h-100"]].join(" ")}
        >
          <img src={require(`../../../assets/images/${item?.image}`)} alt="" />
        </Col>
        <Col
          xl={7}
          lg={12}
          md={12}
          sm={12}
          className={[classes["description-wrapper"], classes["h-100"]].join(
            " "
          )}
        >
          <div className={classes["main-heading"]}>
            <h1>
              {item?.title}{" "}
              <span style={{ fontSize: "12px" }}>({item?.subTitle})</span>
            </h1>
          </div>
          <div className={classes["details"]}>
            <ul>
              {item?.description?.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>
        </Col>
      </Row>
    </CommonCard>
  );
};

export default CarouselCard5;
