import React from "react";
import classes from "./Loader.module.css";
const Loader = ({ className }) => {
  return (
    <div className={[classes["loading-wave"], className].join(" ")}>
      <div className={classes["loading-bar"]}></div>
      <div className={classes["loading-bar"]}></div>
      <div className={classes["loading-bar"]}></div>
      <div className={classes["loading-bar"]}></div>
    </div>
  );
};

export default Loader;
