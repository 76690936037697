import React, { useState } from "react";
import classes from "./Step4.module.css";
import { Row, Col } from "react-bootstrap";
import validator from "validator";
import { Input } from "../../../../components/Input";
import Phone from "../../../../components/Phone";
import FormWrapper from "../../../../components/FormWrapper";

const Step4 = ({ data, setData, setIsValidPhone }) => {
  return (
    <div>
      <FormWrapper>
        <Row>
          <Input
            label={"Private Clinic Name "}
            value={data?.privateClinicName}
            setter={(val) =>
              setData((prev) => ({
                ...prev,
                privateClinicName: val,
              }))
            }
            placeholder={"Enter Clinic Name"}
            labelStyle={{ fontWeight: 600 }}
            required={data?.privateClinicName}
          />
        </Row>
        <Row>
          <Col xl={6} md={6} sm={12}>
            <Input
              type={"number"}
              label={`Phone Clinic Number `}
              placeholder={`Enter Clinic Phone Number`}
              value={data?.privateClinicContactNo}
              setter={(val) => {
                setIsValidPhone(validator?.isMobilePhone(val));
                if (val.length === 1 && val === "0") {
                  return;
                } else {
                  setData((prevData) => {
                    return { ...prevData, privateClinicContactNo: val };
                  });
                }
              }}
              labelStyle={{ fontWeight: 600 }}
              required={data?.privateClinicName}
              salutation={"+92"}
              salutationStyles={{ width: "15%" }}
            />
          </Col>
          <Col xl={6} md={6} sm={12}>
            <Input
              type={"email"}
              label={"Clinic Email Address "}
              value={data.privateClinicEmailAddress}
              setter={(val) =>
                setData((prev) => ({
                  ...prev,
                  privateClinicEmailAddress: val,
                }))
              }
              placeholder={"Enter Clinic Email Address"}
              labelStyle={{ fontWeight: 600 }}
              required={data?.privateClinicName}
            />
          </Col>
        </Row>
        <Row>
          <Input
            label={"Clinic Address "}
            value={data.privateClinicAddress}
            setter={(val) =>
              setData((prev) => ({
                ...prev,
                privateClinicAddress: val,
              }))
            }
            placeholder={"Enter Clinic Address"}
            labelStyle={{ fontWeight: 600 }}
            required={data?.privateClinicName}
          />
        </Row>
      </FormWrapper>
    </div>
  );
};

export default Step4;
