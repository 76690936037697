import React, { useState } from "react";
import SidebarSkeleton from "../SidebarSkeleton";
import { Outlet } from "react-router-dom";

const DashboardLayout = () => {
  return (
    <>
      <SidebarSkeleton>
        <Outlet />
      </SidebarSkeleton>
    </>
  );
};

export default DashboardLayout;
