import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.css";
import CarouselCard from "../CarouselCard";
import data from "./data.json";
import CarouselCard3 from "../CarouselCard/CarouselCard3";
import CarouselCard1 from "../CarouselCard/CarouselCard1";
import CarouselCard2 from "../CarouselCard/CarouselCard2";
import CarouselCard4 from "../CarouselCard/CarouselCard4";
import CarouselCard5 from "../CarouselCard/CarouselCard5";

const CarouselComponent = () => {
  const settings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "0",
    focusOnSelect: true,
    pauseOnHover: true,
    swipeToSlide: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    cssEase: "cubic-bezier(0.25, 0.1, 0.25, 1)",
    dots: true,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <div className="carousel-container">
      <Slider {...settings}>
        {/* {data?.cardData?.map((item, index) => (
          <CarouselCard item={item} key={index} />
        ))} */}
        <CarouselCard1 />
        <CarouselCard2 />
        <CarouselCard3 />
        <CarouselCard4 />
        <CarouselCard5 />
      </Slider>
    </div>
  );
};

const SampleNextArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="arrow next" onClick={onClick}>
      <i className="fas fa-chevron-right"></i>
    </div>
  );
};

const SamplePrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="arrow prev" onClick={onClick}>
      <i className="fas fa-chevron-left"></i>
    </div>
  );
};

export default CarouselComponent;
