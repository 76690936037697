import React from "react";
import classes from "./FacultyCard.module.css";
import CommonCard from "../CommonCard";
import { Row } from "react-bootstrap";
import { defaultProfile } from "../../assets/constant";

const FacultyCard = ({ data, handler }) => {
  return (
    <>
      <CommonCard className={classes["extraCardStyle"]}>
        <Row className={classes["profile-img"]}>
          <img
            src={
              data?.Image
                ? require(`../../assets/images/${data?.Image}`)
                : defaultProfile
            }
            alt=""
          />
        </Row>
        <Row className={classes["main-heading"]}>
          <h1>{data?.Name}</h1>
          <p>{data?.Qualification}</p>
        </Row>
        <Row className={classes["view-btn"]}>
          <p onClick={() => handler(data)}>View Details</p>
        </Row>
      </CommonCard>
    </>
  );
};

export default FacultyCard;
