import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import validator from "validator";
import Button from "../../components/Button";
import CommonCard from "../../components/CommonCard";
import DatePickerComponent from "../../components/DatePickerComponent";
import Dropdown from "../../components/Dropdown";
import FormWrapper from "../../components/FormWrapper";
import { Input } from "../../components/Input";
import classes from "./ReferralForm.module.css";
import Loader from "../../components/Loader";
import moment from "moment";
import Select from "react-select";
import { calculateAgeByDob } from "../../helpers";

function ReferralForm({
  show,
  setShow,
  titleList,
  referToList,
  locationList,
  handler,
  loading,
}) {
  const [isValidPhone, setIsValidPhone] = useState(false);
  const [form, setForm] = useState({
    title: null,
    patientName: "",
    phoneNo: "",
    dob: "",
    age: 0,
    gender: null,
    BCode: null,
    referTo: null,
    suspectedDiagnosis: "",
    referralNotes: "",
  });

  const gender = [
    {
      label: "Male",
      value: "Male",
    },
    {
      label: "Female",
      value: "Female",
    },
  ];
  const submitHandler = (e) => {
    e.preventDefault();
    if (
      form?.title &&
      form?.patientName &&
      form?.phoneNo &&
      form?.dob &&
      form?.gender &&
      form?.referTo &&
      form?.BCode
    ) {
      if (!isValidPhone || form?.phoneNo.length < 10) {
        toast.error("Please enter valid phone Number");
      } else {
        handler(form);
      }
    } else {
      toast.error("Required Fields must be filled");
    }
  };

  useEffect(() => {
    if (form?.dob !== "") {
      calculateAge();
    }
  }, [form?.dob]);

  // useEffect(() => {
  //   if (form?.age !== 0) {
  //     calculateDOB(form?.age);
  //   }
  // }, [form?.age]);

  useEffect(() => {
    if (form?.title !== null) {
      setGender(form?.title?.value);
    }
  }, [form?.title]);

  const calculateDOB = (age) => {
    const date = new Date();
    const currentYear = date.getFullYear();
    const calculateYear = currentYear - age;
    date.setFullYear(calculateYear);
    setForm((prev) => ({
      ...prev,
      dob: date,
    }));
  };

  const calculateAge = () => {
    const age = calculateAgeByDob(form?.dob);
    setForm((prev) => ({
      ...prev,
      age: age,
    }));
  };

  const setGender = (val) => {
    if (val === 1 || val === 4) {
      setForm((prev) => ({
        ...prev,
        gender: {
          value: "Male",
          label: "Male",
        },
      }));
    } else if (val === 2 || val === 3) {
      setForm((prev) => ({
        ...prev,
        gender: {
          value: "Female",
          label: "Female",
        },
      }));
    } else {
      setForm((prev) => ({
        ...prev,
        gender: null,
      }));
    }
  };

  const cancelHandler = () => {
    setShow();
    setForm({
      title: "",
      patientName: "",
      phoneNo: "",
      dob: "",
      age: 0,
      gender: null,
      BCode: null,
      referTo: null,
      suspectedDiagnosis: "",
      referralNotes: "",
    });
  };

  return (
    <>
      <style>{`
      .modal-content{
        border-radius: 16px;
      }
    `}</style>
      <Modal
        show={show}
        onHide={setShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <CommonCard className={classes["extraCardStyles"]}>
          {loading ? (
            <Loader className={classes["extraLoaderStyles"]} />
          ) : (
            <>
              <div className={classes["heading-wrapper"]}>
                <h1>Referral form</h1>
                <p>
                  Fill out the referral form below to recommend someone for our
                  services.
                </p>
              </div>
              <div>
                <FormWrapper handlerFunction={submitHandler}>
                  <Row>
                    <Col xl={3} md={4} sm={12}>
                      <Dropdown
                        label={"Title "}
                        value={form?.title}
                        setter={(val) => {
                          setForm((prev) => ({
                            ...prev,
                            title: val,
                          }));
                        }}
                        placeholder={"Mr."}
                        required={true}
                        labelStyle={{ fontWeight: 600 }}
                        options={titleList?.map((form) => {
                          return {
                            label: form?.salutationName,
                            value: form?.salutationId,
                          };
                        })}
                        isSearchable={true}
                      />
                      {/* <Select value={form?.gender} options={gender} /> */}
                    </Col>
                    <Col xl={9} md={8} sm={12}>
                      <div className={classes["dr-wrapper"]}>
                        <Input
                          label={"Patient Name "}
                          value={form.patientName}
                          setter={(val) =>
                            setForm((prev) => ({
                              ...prev,
                              patientName: val,
                            }))
                          }
                          placeholder={"Enter Patient Name"}
                          required={true}
                          labelStyle={{ fontWeight: 600 }}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={6} md={12} sm={12}>
                      <Input
                        type={"number"}
                        label={`Phone Number `}
                        placeholder={`Enter Phone Number`}
                        value={form?.phoneNo}
                        setter={(val) => {
                          setIsValidPhone(validator?.isMobilePhone(val));
                          if (
                            (val.length === 1 && val === "0") ||
                            val.length > 10
                          ) {
                            return;
                          } else {
                            setForm((prev) => ({
                              ...prev,
                              phoneNo: val,
                            }));
                          }
                        }}
                        labelStyle={{ fontWeight: 600 }}
                        required={true}
                        salutation={"+92"}
                        salutationStyles={{ width: "15%", paddingLeft: "0px" }}
                        minlength="10"
                        maxlength="10"
                      />
                    </Col>
                    <Col xl={6} md={12} sm={12}>
                      <Dropdown
                        label={"Refer to the department of "}
                        value={form?.referTo}
                        setter={(val) =>
                          setForm((prev) => ({
                            ...prev,
                            referTo: val,
                          }))
                        }
                        placeholder={"Refer To"}
                        required={true}
                        labelStyle={{ fontWeight: 600 }}
                        options={referToList?.map((form) => {
                          return {
                            label: form,
                            value: form,
                          };
                        })}
                        isSearchable={true}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={6} md={6} sm={12}>
                      <DatePickerComponent
                        label={"Date of Birth "}
                        value={form?.dob}
                        setter={(val) =>
                          setForm((prev) => ({
                            ...prev,
                            dob: val,
                          }))
                        }
                        placeholder={"Date Of Birth"}
                        required={true}
                        labelStyle={{ fontWeight: 600 }}
                      />
                    </Col>
                    <Col xl={6} md={6} sm={12}>
                      <Input
                        // type={"number"}
                        // min={1}
                        label={`Age `}
                        // placeholder={`1`}
                        value={form?.age}
                        setter={(val) => {
                          // if (val === "0") {
                          //   setForm((prev) => ({
                          //     ...prev,
                          //     age: val,
                          //   }));
                          // } else {
                          //   const newValue = val.replace(/^0+/, "");
                          //   setForm((prev) => ({
                          //     ...prev,
                          //     age: newValue,
                          //   }));
                          // }
                        }}
                        labelStyle={{ fontWeight: 600 }}
                        readOnly={true}
                        required={false}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={6} md={12} sm={12}>
                      <Dropdown
                        label={"Gender "}
                        value={form?.gender}
                        setter={(val) => {
                          setForm((prev) => ({
                            ...prev,
                            gender: val,
                          }));
                        }}
                        placeholder={"Gender"}
                        required={true}
                        labelStyle={{ fontWeight: 600 }}
                        options={gender}
                        isSearchable={true}
                      />
                    </Col>
                    <Col xl={6} md={12} sm={12}>
                      <Dropdown
                        label={"Select Nearest Location "}
                        value={form?.BCode}
                        setter={(val) => {
                          setForm((prev) => ({
                            ...prev,
                            BCode: val,
                          }));
                        }}
                        placeholder={"Select Nearest Location"}
                        required={true}
                        labelStyle={{ fontWeight: 600 }}
                        options={locationList?.map((form) => {
                          return {
                            label: form.name,
                            value: form.code,
                          };
                        })}
                        isSearchable={true}
                      />
                    </Col>
                  </Row>
                  {/* <Row>
                    <Col xl={12} md={12} sm={12}>
                      <Input
                        label={"Suspected Diagnosis "}
                        value={form?.suspectedDiagnosis}
                        setter={(val) =>
                          setForm((prev) => ({
                            ...prev,
                            suspectedDiagnosis: val,
                          }))
                        }
                        placeholder={"Enter Suspected Diagnosis"}
                        labelStyle={{ fontWeight: 600 }}
                      />
                    </Col>
                  </Row> */}
                  <Row>
                    <Col xl={12} md={12} sm={12}>
                      <Input
                        label={"Referral Notes "}
                        value={form?.referralNotes}
                        setter={(val) =>
                          setForm((prev) => ({
                            ...prev,
                            referralNotes: val,
                          }))
                        }
                        placeholder={"Enter Your Referral Notes"}
                        labelStyle={{ fontWeight: 600 }}
                        textArea={true}
                        rows={6}
                      />
                    </Col>
                  </Row>
                  <div className={classes["action-wrapper"]}>
                    <Button
                      label="Cancel"
                      type="button"
                      className={classes["cancel-button"]}
                      onClick={cancelHandler}
                    />
                    <Button
                      label="Save Record"
                      type="submit"
                      className={classes["signIn-button"]}
                    />
                  </div>
                </FormWrapper>
              </div>
            </>
          )}
        </CommonCard>
      </Modal>
    </>
  );
}
export default ReferralForm;
