import React, { useEffect, useState } from "react";
import classes from "./Faculty.module.css";
import Navbar from "../../../components/Navbar";
import FacultyCard from "../../../components/FacultyCard";
import { Col, Row } from "react-bootstrap";
import data from "./data.json";
import FacultyDetailsModal from "../../../modals/FacultyDetailsModal";
import { Input } from "../../../components/Input";
import { CiSearch, CiLocationOn } from "react-icons/ci";
import { PiMedalLight } from "react-icons/pi";

const Faculty = () => {
  const [show, setShow] = useState(false);
  const [facultyList, setFacultyList] = useState(data?.facultyList);
  const [details, setDetails] = useState(null);
  const [search, setSearch] = useState("");
  const [location, setLocation] = useState("");
  const [specialization, setSpecialization] = useState("");

  const showDetailsModal = (data) => {
    setShow(true);
    setDetails(data);
  };

  const filteredData = facultyList?.filter(
    (faculty) => faculty.Name.toLowerCase()?.includes(search.toLowerCase())
    // ||
    // faculty?.City.includes(location) ||
    // faculty?.SpecializationSection.includes(specialization)
  );

  return (
    <div>
      <Navbar title={"Faculty"} />
      <div>
        <div className={classes["filters-wrapper"]}>
          <Row style={{ width: "100%" }}>
            <Col lg={6} md={6} sm={12}>
              <Input
                placeholder={"Search by Name"}
                leftIcon={
                  <CiSearch
                    size={20}
                    style={{ marginTop: -5, color: "var(--placeholder-color)" }}
                  />
                }
                extraInputBox={classes["extraInputBox"]}
                setter={setSearch}
                value={search}
              />
            </Col>
            <Col
              xl={2}
              className="d-xl-block d-lg-none d-md-none d-sm-none"
            ></Col>
            <Col xl={2} lg={3} md={3} sm={6}>
              <Input
                placeholder={"All Location"}
                leftIcon={
                  <CiLocationOn
                    size={20}
                    style={{ marginTop: -5, color: "var(--placeholder-color)" }}
                  />
                }
                extraInputBox={classes["extraInputBox"]}
                setter={setLocation}
                value={location}
              />
            </Col>
            <Col xl={2} lg={3} md={3} sm={6}>
              <Input
                placeholder={"Speciality"}
                leftIcon={
                  <PiMedalLight
                    size={20}
                    style={{ marginTop: -5, color: "var(--placeholder-color)" }}
                  />
                }
                extraInputBox={classes["extraInputBox"]}
                setter={setSpecialization}
                value={specialization}
              />
            </Col>
          </Row>
        </div>
        <Row className={classes["main-div"]}>
          {filteredData?.map((item, index) => (
            <Col
              key={index}
              xl={3}
              md={4}
              sm={6}
              xs={12}
              style={{ margin: "20px 0px" }}
            >
              <FacultyCard data={item} handler={showDetailsModal} />
            </Col>
          ))}
        </Row>
      </div>
      <FacultyDetailsModal
        show={show}
        setShow={() => setShow(false)}
        data={details}
      />
    </div>
  );
};

export default Faculty;
