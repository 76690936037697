import React, { useEffect, useState } from "react";
import { Get, Post } from "../../../AxiosCRUDFunctions";
import Navbar from "../../../components/Navbar";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import DocumentDataTable from "../../../components/DataTable/DocumentDataTable";
import Loader from "../../../components/Loader";
import { BaseURL, apiHeader } from "../../../config";
import useDebounce from "../../../customHooks/useDebounce";
import classes from "./Documents.module.css";
import { Col, Row } from "react-bootstrap";
import { Input } from "../../../components/Input";
import { CiSearch, CiLocationOn } from "react-icons/ci";
import CommonCard from "../../../components/CommonCard";
import { pdf, word } from "../../../assets/constant";
import { saveAs } from "file-saver";
import { getDocument } from "pdfjs-dist";

const Documents = () => {
  const datas = [
    {
      fileName: "Test1.pdf",
      fileSize: "10 MB",
      fileUploaded: "Aug 5, 2023",
      fileUpdated: "May 20, 2024",
    },
    {
      fileName: "Test2.docx",
      fileSize: "10 MB",
      fileUploaded: "Aug 5, 2023",
      fileUpdated: "May 20, 2024",
    },
    {
      fileName: "Test3.pdf",
      fileSize: "10 MB",
      fileUploaded: "Aug 5, 2023",
      fileUpdated: "May 20, 2024",
    },
    {
      fileName: "Test3.pdf",
      fileSize: "10 MB",
      fileUploaded: "Aug 5, 2023",
      fileUpdated: "May 20, 2024",
    },
    {
      fileName: "Test3.pdf",
      fileSize: "10 MB",
      fileUploaded: "Aug 5, 2023",
      fileUpdated: "May 20, 2024",
    },
    {
      fileName: "Test3.pdf",
      fileSize: "10 MB",
      fileUploaded: "Aug 5, 2023",
      fileUpdated: "May 20, 2024",
    },
    {
      fileName: "Test3.pdf",
      fileSize: "10 MB",
      fileUploaded: "Aug 5, 2023",
      fileUpdated: "May 20, 2024",
    },
  ];
  const [show, setShow] = useState(false);
  const [data, setData] = useState(datas);
  const [location, setLocation] = useState("");
  const [allPatientRefferalData, setAllPatientRTefferalData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [specialization, setSpecialization] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState({ label: 10, value: 10 });
  const { token } = useSelector((state) => state?.authReducer);
  const debouncedSearch = useDebounce(search, 600);
  const getAllRefferalPatientData = async (
    pageNo = 1,
    limit = 10,
    search = "",
    sortField = "",
    sortDirection = "desc"
  ) => {
    setLoading(true);
    // const response = await Post(
    //   BaseURL("ReferralPatients/GetAllReferralPatientsListing"),
    //   {
    //     pageNumber: pageNo,
    //     pageSize: limit,
    //     search,
    //     sortField: "",
    //     sortDirection: "",
    //   },
    //   apiHeader(token)
    // );
    // setAllPatientRTefferalData(response?.data?.body?.data || []);
    // setTotalCount(response?.data?.body?.pagination?.totalRowsCount || 0);
    setLoading(false);
  };

  useEffect(() => {
    getAllRefferalPatientData(1, limit?.value, search);
    setPage(1);
  }, [debouncedSearch, limit]);

  const getById = async (id) => {
    setLoading(true);
    const response = await Get(
      BaseURL(`ReferralPatients/GetPatientDetailByReferralPatientID?Id=${id}`),
      token
    );
    if (response?.data?.body !== "") {
      setData(response?.data?.body);
      setLoading(false);
      setShow(true);
    } else {
      toast.error("Something Went Wrong");
    }
    setLoading(false);
  };
  const handlePrint = async (item) => {
    const response = await Get(BaseURL(``), token, true);
    if (response?.data?.body !== "") {
      const blob = new Blob([response.data], { type: "application/pdf" });

      const blobUrl = URL.createObjectURL(blob);

      const pdf = await getDocument(blobUrl).promise;
      const printWindow = window.open("", "_blank");
      printWindow.document.write(
        "<html><head><title>Print PDF</title></head><body></body></html>"
      );

      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
        const page = await pdf.getPage(pageNum);
        const viewport = page.getViewport({ scale: 1 });
        canvas.width = viewport.width;
        canvas.height = viewport.height;
        await page.render({ canvasContext: ctx, viewport }).promise;
        printWindow.document.body.appendChild(canvas.cloneNode(true));
      }

      printWindow.document.close();
      printWindow.focus();
      printWindow.print();
      printWindow.close();
    } else {
      toast.error("Error printing the PDF");
    }
  };
  const handleDownload = async (item) => {
    console.log(item);
    setLoading(true);
    const response = await Get(BaseURL(``), token, true);
    console.log(response?.data);
    if (response?.data?.body !== "") {
      const blob = new Blob([response?.data], { type: "application/pdf" });
      saveAs(blob, "downloaded-file.pdf");
    } else {
      toast.error("Error downloading the file");
    }
    setLoading(false);
  };
  return (
    <div>
      <Navbar title={"Documents"} />
      {loading ? (
        <Loader />
      ) : (
        <>
          {/* <div className={classes["recent-wrapper"]}>
            <div className={classes["filters-wrapper"]}>
              <Row style={{ width: "100%" }}>
                <Col lg={6} md={6} sm={12} style={{ marginBottom: "10px" }}>
                  <Input
                    placeholder={"Search by Name"}
                    leftIcon={
                      <CiSearch
                        size={20}
                        style={{
                          marginTop: -5,
                          color: "var(--placeholder-color)",
                        }}
                      />
                    }
                    extraInputBox={classes["extraInputBox"]}
                    setter={setSearch}
                    value={search}
                  />
                </Col>
                <Col
                  xl={4}
                  lg={3}
                  md={3}
                  className="d-xl-block d-lg-block d-md-block d-sm-none"
                ></Col>
                <Col xl={2} lg={3} md={3} sm={6}>
                  <Input
                    placeholder={"All Location"}
                    leftIcon={
                      <CiLocationOn
                        size={20}
                        style={{
                          marginTop: -5,
                          color: "var(--placeholder-color)",
                        }}
                      />
                    }
                    extraInputBox={classes["extraInputBox"]}
                    setter={setLocation}
                    value={location}
                  />
                </Col>
              </Row>
            </div>
            <div style={{ padding: "15px", paddingBottom: "0px" }}>
              <Row>
                {datas?.map(
                  (item, index) =>
                    index < 4 && (
                      <Col key={index} xl={3} lg={4} md={4} sm={6}>
                        <CommonCard className={classes["extraStyles"]}>
                          <Row className={classes["recentCardRow"]}>
                            <Col lg={3} md={3} sm={12}>
                              {item?.fileName?.split(".")[1] === "pdf" ? (
                                <img
                                  className={classes["pdfImage"]}
                                  src={pdf}
                                  alt="pdf"
                                />
                              ) : (
                                <img
                                  className={classes["wordImage"]}
                                  src={word}
                                  alt="word"
                                />
                              )}
                            </Col>
                            <Col lg={9} md={9} sm={12}>
                              <Col lg={6}>
                                <p className={classes["recent-fileName"]}>
                                  {datas[0]?.fileName}
                                </p>
                              </Col>
                              <Col lg={6} style={{ width: "100%" }}>
                                <p className={classes["recent-fileDesc"]}>
                                  {datas[0]?.fileUploaded} -{" "}
                                  {datas[0]?.fileSize}
                                </p>
                              </Col>
                            </Col>
                          </Row>
                        </CommonCard>
                      </Col>
                    )
                )}
              </Row>
            </div>
          </div>
          <DocumentDataTable
            tableActions={{
              data: datas,
              page,
              setPage,
              search,
              setSearch,
              limit,
              setLimit,
              totalCount,
              getAllRefferalPatientData,
            }}
            handleDownload={handleDownload}
            handlePrint={handlePrint}
          /> */}
        </>
      )}
    </div>
  );
};

export default Documents;
