import React, { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Loader from "./components/Loader";
import Router from "./routes";
import "../src/styles/table.css";
const App = () => {
  return (
    <>
      <ToastContainer />
      <BrowserRouter>
        <Suspense fallback={<Loader />}>
          <Router />
        </Suspense>
      </BrowserRouter>
    </>
  );
};

export default App;
