import React from "react";
import { MdArrowLeft, MdArrowRight } from "react-icons/md";
import Button from "../Button";
import { Input } from "../Input";
import classes from "./Pagination.module.css";

import { toast } from "react-toastify";
const Pagination = ({ page, setPage, totalCount, limit }) => {
  const totalPages = Math.ceil(totalCount / limit);
  return (
    <div className={["flex_1rem_gap", classes.wrapper].join(" ")}>
      <Button
        className={[
          "align_grid_1rem_gap",
          "button_transparent",
          classes.fitInResponsive,
        ].join(" ")}
        type="button"
        onClick={() => {
          setPage((current) => {
            if (current > 1) return current - 1;
            toast.warn("page value should be greater than 0");
            return current;
          });
        }}
      >
        <MdArrowLeft size={23} color="var(--steel-gray)" />
        <span className={classes["indicator__name"]}>Back</span>
      </Button>
      <div className={["flex_1rem_gap", classes.inputContainer].join(" ")}>
        <Input
          disabled={true}
          type="number"
          value={page}
          setter={(e) => {
            // if (e < 1) {
            //   return toast.warn("page value should be greater than 1");
            // } else if (e > totalPages) {
            //   return toast.warn(
            //     `page value shouldn't be greater than total pages`
            //   );
            // }
            setPage(e);
          }}
          parentCustomStyle={{
            width: "36px",
            height: "36px",
          }}
          inputStyle={{
            color: "var(--medium-gray)",
            textAlign: "center",
            padding: "16px 0",
          }}
          placeholder=""
          min="1"
        />
        <p className={[classes["indicator__outof"], "font-medium"].join(" ")}>
          of {totalPages}
        </p>
      </div>
      <Button
        className={[
          "align_grid_1rem_gap",
          "button_transparent",
          classes.fitInResponsive,
        ].join(" ")}
        onClick={() => {
          setPage((current) => {
            if (current < totalPages) return current + 1;
            else {
              toast.warn(`Page shouldn't be greater than ${totalPages}`);
            }
            return current;
          });
        }}
      >
        <span className={classes["indicator__name"]}>Next</span>
        <MdArrowRight size={23} color="var(--steel-gray)" />
      </Button>
    </div>
  );
};

export default Pagination;
