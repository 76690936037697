import React, { useState } from "react";
import classes from "./ForgetPassword.module.css";
import Step1 from "./Step1";
import Step2 from "./Step2";

const ForgetPassword = () => {
  const [step, setStep] = useState(1);

  return (
    <div className={classes["main-wrapper"]}>
      {step === 1 ? <Step1 setStep={setStep} /> : <Step2 />}
    </div>
  );
};

export default ForgetPassword;
